import _ from "lodash";
import { _normalizeNames, capFirst } from "../../helpers";
import React, { useEffect, useState, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import {
  doc,
  setDoc,
  addDoc,
  collection,
  getDoc,
  query,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { useTeam, useWriteAccess } from "../../hooks";
const UNSAFE_DOCS = [];
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import LocationRow from "../parts/LocationRow";
import { PROJ } from "../Map/conversionReducer";
import Map from "../Map/Map";
import FgField from "../parts/FgField";

const TYPES_PRIORITY = {
  STATE: 10,
  DISTRICT: 9,
  CAPITAL_CITY: 8,
  CITY: 7,
  URBAN: 6,
  VILLAGE: 5,
  SETTLEMENT: 4,
};

const getOptions = async (type, query) => {
  const res = await __firebase.queryOptions({
    type,
    query,
  });
  if (!_.isArray(res.data)) {
    console.error("sho priyshlo from autocomplete?", res.data);
    return [];
  }
  return res.data;
};

let lastSearchTxt;
const FqLocation = ({ fq, frequency }) => {
  const team = useTeam();
  const writeAccess = useWriteAccess();
  const [searchTxt, setSearchTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const fqmeta = useSelector(({ fqmeta }) => fqmeta[fq]);
  const locationExist =
    _.isPlainObject(fqmeta) &&
    _.isArray(fqmeta.locations) &&
    fqmeta.locations.length > 0;

  const requestAddLocation = async (v) => {
    v = _.omit(v, "label");
    console.log("requestAddLocation", v);

    try {
      let res;
      const common = {
        team,
        fq,
        updatedAt: new Date().valueOf(),
      };
      if (_.isPlainObject(fqmeta)) {
        res = setDoc(
          doc(db, "fqmeta", fqmeta._id),
          {
            ...common,
            locations: fqmeta.locations ? [...fqmeta.locations, v] : [v],
          },
          { merge: true }
        );
      } else {
        res = addDoc(collection(db, "fqmeta"), {
          ...common,
          locations: [v],
        });
      }
      console.log("success add/edit location", res);
    } catch (err) {
      setError(err.toString());
    }
  };
  const term = searchTxt.toLowerCase().trim();
  useEffect(() => {
    lastSearchTxt = searchTxt;
    if (term.length < 2) {
      setOptions([]);
      return;
    }
    (async () => {
      if (!loading) setLoading(true);
      let options = await getOptions("locations", term);

      if (lastSearchTxt !== searchTxt) {
        return;
      }

      if (locationExist) {
        // options = options.filter(({ id }) =>
        //   fqmeta.locations.every((v) => v.id !== id)
        // );
      }
      const newOptions = options.map((r) => ({
        label: r.name_ua,
        ...r,
      }));
      setLoading(false);
      setOptions(newOptions);
    })();
    return () => {
      lastSearchTxt = "";
    };
  }, [searchTxt]);

  const locationsForDisplay = !locationExist
    ? []
    : fqmeta.locations.filter(({ lat, lng }) => {
        return lat && lng;
      });
  const locationsNonDisplayed = !locationExist
    ? []
    : fqmeta.locations.filter(({ lat, lng }) => {
        return !lat || !lng;
      });

  return (
    <div className={"fq__loc container-fluid"}>
      <div className="row" style={{ marginBottom: "18px" }}>
        <div className="col">
          <FgField
            fq={fq}
            title={"Замітки по можливим локаціям"}
            name={"location"}
            note={"Де стріляли, про які позиції говорили"}
          />
        </div>
      </div>
      {error && (
        <div className="row">
          <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
        </div>
      )}
      <div className="row">
        <div className="col">
          <div className="fq__loc-row">
            <div className="fq__loc-fields">
              {locationExist && (
                <div className="fq__loc-list">
                  {fqmeta.locations.map((data) => {
                    return (
                      <LocItem
                        key={data.id}
                        data={data}
                        fqmeta={fqmeta}
                        team={team}
                        fq={fq}
                        setError={setError}
                        writeAccess={writeAccess}
                      />
                    );
                  })}
                </div>
              )}
              {writeAccess && (
                <Autocomplete
                  open={term.length > 2}
                  className={"fq__loc-autocomplete"}
                  getOptionDisabled={
                    locationExist
                      ? ({ id }) => {
                          return fqmeta.locations.some((v) => v.id === id);
                        }
                      : null
                  }
                  disablePortal
                  options={options}
                  loading={loading}
                  loadingText={"Завантажуємо..."}
                  filterOptions={(x) => x}
                  // clearIcon={null}
                  noOptionsText={"Нічого не знайдено"}
                  onChange={(event, newValue) => {
                    if (_.isPlainObject(newValue) && newValue.label) {
                      setSearchTxt("");
                      requestAddLocation(newValue);
                    }
                  }}
                  onInputChange={(ev) => {
                    if (!ev) return; // something strange, its null sometimes
                    const { target } = ev;
                    if (typeof target.value === "string") {
                      setSearchTxt(target.value);
                    } else {
                      setSearchTxt("");
                    }
                  }}
                  renderOption={(props, obj) => {
                    return (
                      <div
                        {...props}
                        className={props.className + " fq__loc-option"}
                        key={obj.id}
                      >
                        <div>
                          <LocationRow loc={obj} />
                        </div>
                      </div>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={`Додати населений пункт`}
                        variant="outlined"
                        value={searchTxt}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            ...params.inputProps.style,
                            // minWidth: value.length * 7,
                          },
                          value: searchTxt,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    );
                  }}
                />
              )}
            </div>
            {locationExist ? (
              <div className="fq__loc-map">
                {locationsNonDisplayed.length > 0 && (
                  <div style={{ margin: "5px 0" }}>
                    Не показується, бо немаємо координат:{" "}
                    <i>
                      {locationsNonDisplayed
                        .map(({ name_ua }) => capFirst(name_ua))
                        .join(", ")}
                    </i>
                  </div>
                )}
                <Map
                  objects={locationsForDisplay.map(
                    ({ lat, lng, id, name_ua }) => ({
                      type: "point",
                      key: id,
                      value: {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng),
                      },
                      label: capFirst(name_ua),
                    })
                  )}
                  center={"auto"}
                  zoom={locationsForDisplay.length === 1 ? 12 : "auto"}
                />
              </div>
            ) : (
              <div className={"fq__loc-mapzone"}>
                Оберіть міста-поселення, поряд з якими оперує ворог
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const LocItem = ({ data, fqmeta, team, fq, setError, writeAccess }) => {
  const requestRemoveLocation = () => {
    try {
      let res;
      const common = {
        team,
        fq,
        updatedAt: new Date().valueOf(),
      };
      res = setDoc(
        doc(db, "fqmeta", fqmeta._id),
        {
          ...common,
          locations: fqmeta.locations.filter(({ id }) => id !== data.id),
        },
        { merge: true }
      );
      console.log("success remove location", res);
    } catch (err) {
      setError(err.toString());
    }
  };
  return (
    <div className="fq__loc-item">
      <NavLink to={"/location/" + data.id}>
        <LocationRow loc={data} />
      </NavLink>
      {writeAccess && (
        <div className="fq__loc-item-close" onClick={requestRemoveLocation}>
          <IconButton aria-label="delete" size="small">
            <HighlightOffIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default FqLocation;
