import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { normalizeFq } from "../../helpers";
import classNames from "classnames";

const DraftsList = React.memo(
  ({ currentFQ }) => {
    const fqs = useSelector(
      ({ drafts }) => _.keys(drafts).map(parseFloat).sort(),
      _.isEqual
    );
    if (fqs.length === 0 || fqs.filter((f) => f !== currentFQ).length === 0) {
      return null;
    }

    return (
      <div className={"messages__anotherfq"}>
        {fqs.map((fq) => {
          return (
            <Link
              to={"/fq/" + normalizeFq(fq)}
              key={fq}
              className={classNames("fq-cross-link", {
                "fq-cross-link-active": fq === currentFQ,
              })}
            >
              {normalizeFq(fq)}
            </Link>
          );
        })}
      </div>
    );
  },
  (p, n) => p.currentFQ === n.currentFQ
);
export default DraftsList;
