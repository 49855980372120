import { actionHandlers } from "../../helpers";
import _ from "lodash";
import {
  convertCK42ToWGS84,
  convertUTMToWGS84,
  convertWGS84ToUTM,
  wgs84ToSk42,
} from "./maptools";

export const PROJ = {
  CK42: "CK42",
  WGS84: "WGS84",
  UTM: "UTM",
};

export const DEFAULT = {
  [PROJ.CK42]: { x: "54", y: "74", zone: "7" },
  [PROJ.WGS84]: { lat: null, lng: null },
  [PROJ.UTM]: {
    easting: null,
    northing: null,
    zoneLetter: "U",
    zoneNum: "37",
  },
};

const conversionReducer = actionHandlers(
  {
    ["FROM_TEXTAREA"]: (state, payload) => {
      const newState = {
        x: payload.x ? "54" + payload.x : "",
        y: payload.y ? "74" + payload.y : "",
        zone: state[PROJ.CK42].zone,
      };
      return {
        ...state,
        [PROJ.CK42]: newState,
        [PROJ.WGS84]: convertCK42ToWGS84(newState),
      };
    },
    [PROJ.CK42]: (state, payload) => {
      const newProjState = {
        ...state[PROJ.CK42],
        ...payload,
      };
      const conversion = {};

      const isReady =
        _.isString(newProjState.x) &&
        newProjState.x.length === 7 &&
        _.isString(newProjState.y) &&
        newProjState.y.length === 7 &&
        _.isString(newProjState.zone) &&
        newProjState.zone.length === 1;

      if (isReady) {
        try {
          conversion[PROJ.WGS84] = convertCK42ToWGS84(newProjState);
        } catch (err) {
          conversion[PROJ.WGS84] = DEFAULT[PROJ.WGS84];
        }
        try {
          conversion[PROJ.UTM] = convertWGS84ToUTM(conversion[PROJ.WGS84]);
        } catch (err) {
          conversion[PROJ.UTM] = DEFAULT[PROJ.UTM];
        }
      } else {
        conversion[PROJ.WGS84] = DEFAULT[PROJ.WGS84];
        conversion[PROJ.UTM] = DEFAULT[PROJ.UTM];
      }
      return {
        ...state,
        [PROJ.CK42]: newProjState,
        ...conversion,
      };
    },
    [PROJ.WGS84]: (state) => {
      return state;
    },
    [PROJ.UTM]: (state, payload) => {
      const newProjState = {
        ...state[PROJ.UTM],
        ...payload,
      };
      const conversion = {};

      const isReady =
        _.isString(newProjState.easting) &&
        newProjState.easting.length > 5 &&
        _.isString(newProjState.northing) &&
        newProjState.northing.length > 5;

      if (isReady) {
        try {
          conversion[PROJ.WGS84] = convertUTMToWGS84(newProjState);
        } catch (err) {
          conversion[PROJ.WGS84] = DEFAULT[PROJ.WGS84];
        }
        try {
          conversion[PROJ.CK42] = wgs84ToSk42(conversion[PROJ.WGS84]);
        } catch (err) {
          conversion[PROJ.CK42] = DEFAULT[PROJ.CK42];
        }
      } else {
        conversion[PROJ.WGS84] = DEFAULT[PROJ.WGS84];
        conversion[PROJ.CK42] = DEFAULT[PROJ.CK42];
      }
      return {
        ...state,
        [PROJ.UTM]: newProjState,
        ...conversion,
      };
    },
  },
  DEFAULT
);

export default conversionReducer;
