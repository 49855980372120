import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { normalizeFq } from "../../helpers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import isDateValid from "date-fns/isValid";

const DateTime = React.memo(
  ({ datetime, setDatetime }) => {
    return (
      <div className="create-message__datetime">
        <div className="create-message__datetime-field" style={{ width: 130 }}>
          <DatePicker
            label="Дата"
            value={new Date(datetime)}
            onChange={(newValue) => {
              setDatetime(newValue.valueOf());
            }}
            componentsProps={{ OpenPickerButton: { tabIndex: -1 } }}
            slotProps={{ OpenPickerButton: { tabIndex: -1 } }}
            tabIndex={-1}
            minDate={datetime - 1000 * 60 * 60 * 24 * 5}
            disableFuture
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  size="small"
                  inputProps={{ ...props.inputProps, tabIndex: -1 }}
                  variant="standard"
                  InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
                />
              );
            }}
          />
        </div>
        <div className="create-message__datetime-time">
          <TimePicker
            label="Час"
            value={new Date(datetime)}
            onChange={(newValue) => {
              if (isDateValid(newValue)) {
                setDatetime(newValue.valueOf());
              }
            }}
            disableFuture
            selectedSections={"all"}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  // disableOpenPicker
                  variant="standard"
                  size="small"
                  inputProps={{ ...props.inputProps, tabIndex: -1 }}
                  onKeyDown={(ev) => {
                    if (ev.code === "ArrowUp") {
                      setDatetime(datetime + 1000 * 60);
                    } else if (ev.code === "ArrowDown") {
                      setDatetime(datetime - 1000 * 60);
                    }
                  }}
                  InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
                />
              );
            }}
            // renderInput={(props) => {
            //   return (
            //     <TextField
            //       {...props}
            //       variant="standard"
            //       // type={"number"}
            //       defaultValue={"15.03.1990"}
            //       onChange={(ev) => console.log(ev.target.value)}
            //       InputLabelProps={{ shrink: true }}
            //     />
            //   );
            // }}
          />
        </div>
      </div>
    );
  },
  (prev, next) => {
    return prev.datetime === next.datetime;
  }
);

export default DateTime;

// <div className="create-message__fq">
//   <TextField
//     disabled
//     label={`Частота`}
//     variant="standard"
//     // type={"number"}
//     defaultValue={normalizeFq(fqID)}
//     onChange={(ev) => console.log(ev.target.value)}
//     InputLabelProps={{ shrink: true }}
//   />
// </div>
// <div className="create-message__title">
//   <TextField
//     label={`Шапка`}
//     variant="standard"
//     // type={"number"}
//     fullWidth
//     disabled
//     value={title}
//     onChange={(ev) => setTitle(ev.target.value)}
//     InputLabelProps={{ shrink: true }}
//   />
// </div>
