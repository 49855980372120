import _ from "lodash";
import store from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { capFirst } from "../helpers";

const subscribeIframeEvents = _.once(({ navigate }) => {
  window.addEventListener("message", (ev) => {
    const { data } = ev;
    // -------------------------------------------------------------- legacy
    if (typeof ev.data === "number") {
      navigate("/fq/" + ev.data);
      return;
    }
    // --------------------------------------------------------------
    let action = ev.data;
    try {
      if (typeof action === "string") {
        action = JSON.parse(ev.data);
      }
    } catch (err) {}

    if (typeof action.type !== "string") {
      // console.error("Unknown message from parent", action);
      return;
    }
    if (action.from === "PLAYER") {
      if (action.type === "FQ_SET") {
        navigate("/fq/" + action.payload.fq);
        // onFQset(action.payload.fq);
      } else if (action.type === "CHANGE_SELECTION") {
        store.dispatch({
          type: "PLAYER_" + action.type,
          payload: {
            fq: action.payload.fq,
            list: action.payload.list,
          },
        });
      } else if (action.type === "SELECTED_CHANGE") {
        store.dispatch({
          type: "PLAYER_" + action.type,
          payload: action.payload,
        });
      }
      return;
    }
    console.log("UNKNOWN ACTION FROM PARENT", action);
  });
  return () => {
    // todo: removeEventListener
  };
});

export const postToPlayer = (action) => {
  window.parent.postMessage(
    JSON.stringify({
      from: "PROFILER",
      to: "PLAYER",
      ...action,
    }),
    "*"
  );
};

const IframeCommunication = () => {
  const params = useParams();

  const authorised = useSelector(({ profile }) => profile.authorised);
  const frequencies = useSelector(({ frequencies }) => frequencies);
  const fqmeta = useSelector(({ fqmeta }) => fqmeta);
  const drafts = useSelector(({ drafts }) => drafts);
  const navigate = useNavigate();
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    const fq = parseFloat(params.fqID);
    if (!fq) return;
    postToPlayer({
      type: "FQ_SET_REQUEST",
      payload: { fq },
    });
  }, [params.fqID]);

  useEffect(() => {
    if (authorised) {
      subscribeIframeEvents({ navigate });
    }
  }, [authorised]);

  // Post "ALT_SPACE_PRESSED" event to player, to suspend player playing
  useEffect(() => {
    const handler = (e) => {
      if (e.code === "Space" && e.altKey) {
        postToPlayer({
          type: "ALT_SPACE_PRESSED",
        });
      }
    };

    document.addEventListener("keydown", handler);
    return () => document.removeEventListener("keydown", handler);
  }, []);

  useEffect(() => {
    try {
      const fq_list = _.uniq([..._.keys(frequencies), ..._.keys(fqmeta)]);

      const chat_titles = fq_list.reduce((acc, fq) => {
        if (
          frequencies[fq] &&
          typeof frequencies[fq].chat_title === "string" &&
          frequencies[fq].chat_title.trim().length > 0
        ) {
          acc[fq] = frequencies[fq].chat_title;
        } else if (fqmeta[fq]) {
          let chat_title = "";
          const isLocationsExist =
            _.isArray(fqmeta[fq].locations) && fqmeta[fq].locations.length > 0;
          const isFactionsExist =
            _.isArray(fqmeta[fq].factions) && fqmeta[fq].factions.length > 0;
          if (isLocationsExist) {
            chat_title += fqmeta[fq].locations
              .map(({ name_ua }) => capFirst(name_ua))
              .join(", ");
          }
          if (isFactionsExist) {
            chat_title +=
              (isLocationsExist ? " | " : "") +
              fqmeta[fq].factions
                .map(({ short_name }) => capFirst(short_name))
                .join(", ");
          }
          if (chat_title) {
            acc[fq] = chat_title;
          }
        }
        return acc;
      }, {});

      // const chat_titles = _(frequencies)
      //   .mapValues("chat_title")
      //   .pickBy((v) => v != null && v.trim() !== "")
      //   .value();

      if (_.isEmpty(chat_titles)) return;

      postToPlayer({
        type: "CHAT_TITLES",
        payload: chat_titles,
        initial,
      });

      if (initial) {
        // postToPlayer({
        //   type: "DRAFTS_INITIAL",
        //   payload: _(drafts)
        //     .mapValues(({ audio }, fq) => audio)
        //     .pickBy((audio) => audio.length > 0)
        //     .value(),
        // });
        setInitial(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [frequencies, fqmeta]);

  return null;
};
export default IframeCommunication;
