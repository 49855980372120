import Button from "@mui/material/Button";
import React from "react";
import TextField from "@mui/material/TextField";

const Tags = ({ tags, setTags }) => {
  return null;
  return (
    <div className="create-message__abonents">
      {tags.map(({ name }, i) => {
        return <Tag key={name + i} num={i + 1} name={name} />;
      })}
      <Button variant="outlined" size={"small"}>
        додати тег
      </Button>
    </div>
  );
};
const Tag = ({ name, bearings, num, addBearing }) => {
  return (
    <div className="create-message__abonent">
      <div className="create-message__abonent-name">
        <TextField
          label={`тег ${num}`}
          variant="outlined"
          size="small"
          value={name}
        />
      </div>
      {/*<div className="create-message__abonent-pelengform">*/}
      {/*  <PelengForm bearings={bearings} addBearing={addBearing} />*/}
      {/*</div>*/}
    </div>
  );
};

export default Tags;
