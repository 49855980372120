import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import React from "react";

export function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <Alert severity="error">
      <p>
        <b>Шось трапилось з чорновиком</b>
      </p>
      <p>{error.message}</p>
      <p>Спробуйте перезавантажити сторінку, якщо не вийде то:</p>
      <p>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            resetErrorBoundary(false);
          }}
        >
          Видалити чорновик
        </Button>{" "}
        або{" "}
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            resetErrorBoundary(true);
          }}
        >
          Видалити всі чорновики
        </Button>
      </p>
      <p>Та знову перезавантажити сторінку</p>
    </Alert>
  );
}
