import React from "react";
import { uniqAndOrder } from "../../../helpers";

class NamesCleaner extends React.Component {
  state = {
    value: "",
  };
  onChange = ({ target }) => {
    this.setState({
      value: target.value,
    });
  };

  getCleanValue = () => {
    let v = this.state.value
      .trim()
      .split(",")
      .map((n) => n.trim().replace(/\s/, " "))
      .filter((n) => n !== "");

    v = uniqAndOrder(v);

    return v.join(", ");
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <textarea
              value={this.state.value}
              onChange={this.onChange}
              style={{ width: "100%", height: "300px" }}
            ></textarea>
          </div>
          <div className="col-sm">
            <textarea
              value={this.getCleanValue()}
              style={{ width: "100%", height: "300px" }}
              onClick={({ target }) => target.select()}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default NamesCleaner;
