import { ErrorBoundary } from "react-error-boundary";
import Alert from "@mui/material/Alert";
import React from "react";
import copy from "copy-to-clipboard";
import { formatMessage, structureMessage } from "../../helpers";
import Highlighter from "react-highlight-words";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";
import ukLocale from "date-fns/locale/uk";
import { TOKENS } from "../../semantic";

const Message = (props) => {
  return (
    <ErrorBoundary
      fallback={
        <Alert severity="error" style={{ margin: "5px 0" }}>
          Виникла помилка при відображенні перехоплення
        </Alert>
      }
    >
      {React.createElement(MessageComponent, props)}
    </ErrorBoundary>
  );
};
const MessageComponent = React.memo(
  ({ data, tokens }) => {
    return (
      <div
        className={"message"}
        onDoubleClick={() => console.log("message", data)}
      >
        <div
          className="message__copy"
          onClick={() => {
            copy(data.__signal ? data.__signal.message : data.text);
          }}
        ></div>
        {structureMessage(data.text).map((row, i) => {
          if (typeof row !== "string") return row;
          return row;
          // return (
          // <Highlighter
          //   key={row + "____" + i}
          //   highlightClassName="message__text-marked"
          //   searchWords={
          //     tokens && tokens.length ? [...TOKENS, ...tokens] : TOKENS
          //   }
          //   autoEscape={true}
          //   textToHighlight={row}
          //   className="message__text"
          //   // highlightTag={(props) => {
          //   //   console.log(123, preparedNames, props.children);
          //   //   if (preparedNames.indexOf(props.children) !== -1) {
          //   //     return React.createElement("span", {
          //   //       ...props,
          //   //       className: props.className + " message__text-marked--name",
          //   //     });
          //   //   } else {
          //   //     return React.createElement("mark", props);
          //   //   }
          //   //   return "span";
          //   // }}
          // />
          // );
        })}
        {/*<div className="message__text">*/}
        {/*  {structureMessage(formatMessage(data.text))}*/}
        {/*</div>*/}
        {data.comment && (
          <div className="message__comment">({data.comment})</div>
        )}
        {data.callsigns.length > 0 && (
          <div className="message__callsigns">{data.callsigns.join(", ")}</div>
        )}
        <div className="message__datetime">
          {typeof data.datetime === "string" &&
            formatDistanceToNow(parseISO(data.datetime), {
              locale: ukLocale,
            })}
          {typeof data.datetime === "number" &&
            formatDistanceToNow(data.datetime, {
              locale: ukLocale,
            })}
        </div>
        <div className="message__audio"></div>
        {/*<div className="message__signal">*/}
        {/*  <pre>{JSON.stringify(data.__signal, null, 2)}</pre>*/}
        {/*</div>*/}
      </div>
    );
  },
  (prev, next) => {
    return prev.data === next.data;
  }
);
export default Message;
