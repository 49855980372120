import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import profileReducer from "./reducers/profile";
import namesReducer from "./reducers/names";
import frequenciesReducer from "./reducers/frequencies";
import daystoryReducer from "./reducers/daystory";
import settingsReducer from "./reducers/settings";
import draftsReducer from "./reducers/drafts";
import messagesReducer from "./reducers/messages";
import fqmetaReducer from "./reducers/fqmeta";
import factionsReducer from "./reducers/factions";
import signalGroupsFqReducer from "./reducers/signalGroupsFq";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const logger = createLogger({
  // diff: __DEV__,
  collapsed: true,
});

const eventTrigger = (events) => (store) => (next) => (action) => {
  if (events[action.type]) {
    const after = events[action.type](store, action);
    const result = next(action);
    if (typeof after === "function") {
      after(store, action, result);
    }
    return result;
  } else {
    return next(action);
  }
};

const TRIGGERS = {
  // SETTINGS_SNAPSHOT: (store, action) => {
  //   console.log(123);
  //   return () => {
  //     console.log(234);
  //   };
  // },
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["drafts", "signalGroupsFq"],
};

const store = createStore(
  persistReducer(
    persistConfig,
    combineReducers({
      profile: profileReducer,
      names: namesReducer,
      frequencies: frequenciesReducer,
      daystory: daystoryReducer,
      settings: settingsReducer,
      drafts: draftsReducer,
      messages: messagesReducer,
      fqmeta: fqmetaReducer,
      factions: factionsReducer,
      signalGroupsFq: signalGroupsFqReducer,
    })
  ),
  applyMiddleware(...[eventTrigger(TRIGGERS), logger])
);

window.store = store;
export const persistor = persistStore(store);

export default store;
