import React from "react";
import _ from "lodash";
import { normalizeFq } from "../../helpers";
import { connect, shallowEqual } from "react-redux";
import TextField from "@mui/material/TextField";
import NoFQData from "../parts/NoFQData";
import Row from "./Row";

const findName = (name, query) => {
  return name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
};

const FQ_PER_PAGE = 30;
class TableView extends React.Component {
  state = {
    activeName: null,
    searchFQValue: "",
    searchNAMESValue: "",
    searchCHATValue: "",
    page: 0,
    allFqs: [],
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
    this.calcFqList(true);
  }

  componentDidMount() {
    this.searchRef.current.focus();
  }

  onClick = (n) => () => {
    this.setState({
      activeName: n,
    });
  };
  onSearchFQChange = ({ target }) => {
    const newValue = target.value
      .trim()
      .replace(",", ".")
      .replace("/", ".")
      .replace("ю", ".")
      .replace("б", ".")
      .replace(":", ".")
      .replace("?", ".")
      .replace(/[^0-9\.]/g, "");

    if (newValue === this.state.searchFQValue) return;

    this.setState({
      searchFQValue: newValue,
      searchNAMESValue: "",
      searchCHATValue: "",
      page: 0,
    });
  };
  onSearchNAMESChange = ({ target }) => {
    const newValue = target.value
      .toLowerCase()
      .trimLeft()
      .replace(",", ".")
      .replace("/", ".");

    if (newValue === this.state.searchNAMESValue) return;

    this.setState({
      searchNAMESValue: newValue,
      searchFQValue: "",
      searchCHATValue: "",
      page: 0,
    });
  };

  onSearchCHATChange = ({ target }) => {
    const newValue = target.value.toLowerCase().trim();
    // .replace(",", ".")
    // .replace("/", ".");

    if (newValue === this.state.searchCHATValue) return;

    this.setState({
      searchNAMESValue: "",
      searchFQValue: "",
      searchCHATValue: newValue,
      page: 0,
    });
  };
  calcFqList = (initial = false) => {
    // console.log("HEAVY calcFqList");
    const { sortedFqList, fqmeta, frequencies } = this.props;
    const allFqs = _.uniq(
      // concat is faster than ...spread for big arrays
      sortedFqList
        .concat(Object.keys(fqmeta))
        .concat(Object.keys(frequencies))
        .map(parseFloat)
    ).sort((a, b) => a - b);
    if (initial) {
      this.state = {
        ...this.state,
        allFqs,
      };
    } else {
      this.setState({
        allFqs,
      });
    }
  };

  static keysForAllFqs = ["sortedFqList", "fqmeta", "frequencies"];

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !shallowEqual(
        _.pick(prevProps, TableView.keysForAllFqs),
        _.pick(this.props, TableView.keysForAllFqs)
      )
    ) {
      this.calcFqList();
    }
  }

  render() {
    const {
      searchFQValue,
      searchNAMESValue,
      searchCHATValue,
      activeName,
      allFqs,
    } = this.state;
    const { allNames, sortedFqList, computed, fqmeta, frequencies } =
      this.props;

    let preparedFqList = allFqs;
    const normalizedSearchFQValue = searchFQValue.trim();
    const normalizedSearchNAMESValue = searchNAMESValue.trim();

    // Search by FQ value
    if (normalizedSearchFQValue) {
      preparedFqList = preparedFqList.filter((fq) => {
        const n = normalizeFq(fq);
        return (
          n.indexOf(normalizedSearchFQValue) === 0 ||
          (n + "0000").indexOf(normalizedSearchFQValue) === 0
        );
      });
    }

    // Search by call sign
    if (normalizedSearchNAMESValue) {
      preparedFqList = preparedFqList.filter((fq) => {
        return _.some(allNames[fq], (n) =>
          findName(n, normalizedSearchNAMESValue)
        );
      });
    }

    // Search by chat_title
    if (searchCHATValue) {
      preparedFqList = preparedFqList.filter(
        (fq) =>
          frequencies[fq]?.chat_title &&
          findName(frequencies[fq].chat_title, searchCHATValue)
      );
    }

    return (
      <div className="fq-table-container">
        <div className="fq-table__search">
          <div className="fq-table__search-field fq-table__search-fq"></div>
          <div className="fq-table__search-field fq-table__search-names"></div>
        </div>

        <div className="fq-table-panel">
          {activeName &&
            computed.nameToFs[activeName].map((fq) => (
              <span style={{ marginRight: "30px" }}>{fq}</span>
            ))}
          &nbsp;
        </div>
        <>
          <table className="table table-stripped table-bordered table-hover table-sm fq-table-table">
            <tbody>
              <tr className={"fq-table__searches"}>
                <th className={"fq-navigate-cell fq-navigate-cell__search"}>
                  <TextField
                    margin="dense"
                    size={"small"}
                    // key={`${proj}[${name}]`}
                    label={"пошук по частоті"}
                    type="search"
                    variant="outlined"
                    inputProps={{
                      ref: this.searchRef,
                      autoComplete: "off",
                    }}
                    fullWidth
                    value={normalizedSearchFQValue}
                    // value={}
                    onInput={this.onSearchFQChange}
                  />
                </th>
                <td>
                  <TextField
                    margin="dense"
                    size={"small"}
                    label={"пошук по позивним"}
                    type="search"
                    variant="outlined"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    // sx={{ width: "250px" }}
                    sx={{ width: "100%" }}
                    value={normalizedSearchNAMESValue}
                    // value={}
                    onInput={this.onSearchNAMESChange}
                  />
                  {/*<TextField
                    margin="dense"
                    size={"small"}
                    label={"пошук по назві чату"}
                    type="search"
                    variant="outlined"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    sx={{width: "250px"}}
                    value={searchCHATValue}
                    // value={}
                    onInput={this.onSearchCHATChange}
                  />*/}
                </td>
              </tr>
              {preparedFqList
                .slice(
                  this.state.page * FQ_PER_PAGE,
                  (this.state.page + 1) * FQ_PER_PAGE
                )
                .map((fq) => {
                  const names = allNames[fq];
                  // console.log(names, fq)
                  const fullFqValue = normalizeFq(fq);

                  return (
                    <Row
                      key={fq}
                      fullFqValue={fullFqValue}
                      nameToFs={computed.nameToFs}
                      names={names}
                      maxValue={computed.maxValue}
                      activeName={activeName}
                      onClick={this.onClick}
                      searchValue={normalizedSearchNAMESValue}
                    />
                  );
                })}
            </tbody>
          </table>
          <Pagination
            preparedFqList={preparedFqList}
            statePage={this.state.page}
            setPage={this.setPage}
          />
        </>
        {preparedFqList.length === 0 && (
          <div className={"fq-table__noresults"}>
            {normalizedSearchFQValue && (
              <NoFQData fq={normalizeFq(normalizedSearchFQValue)} />
            )}
            {normalizedSearchNAMESValue && (
              <div>
                Не знайдено жодних результатів з «{normalizedSearchNAMESValue}»
              </div>
            )}
          </div>
        )}
        {allFqs.length === 0 && (
          <div className={"fq-table__noresults"}>
            <div>
              Не знайдено жодної частоти. Щоб добавити частоту, скористайтесь
              пошуком.
            </div>
          </div>
        )}
      </div>
    );
  }
  setPage = (page) => {
    this.setState({
      page,
    });
  };
}

const Pagination = ({ preparedFqList, statePage, setPage }) => {
  return (
    <nav>
      <ul className="pagination justify-content-center">
        {preparedFqList.length / FQ_PER_PAGE >= 2 &&
          _.times(preparedFqList.length / FQ_PER_PAGE, (i) => {
            return (
              <li
                className={"page-item " + (i === statePage ? "active" : "")}
                key={i}
              >
                <a
                  className={"page-link"}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPage(i);
                  }}
                >
                  {i + 1}
                </a>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default connect(({ names, fqmeta, frequencies }) => ({
  allNames: names.list,
  computed: names.computed,
  sortedFqList: names.sortedFqList,
  fqmeta,
  frequencies,
}))(TableView);
