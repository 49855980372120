export const __DEV__ = process.env.NODE_ENV === "development";

export const FEATURE_FLAGS = {
  daystory: __DEV__,
  whatsapp: __DEV__,
};

export const SERVER_DATE_FORMAT = "yyyy/MM/dd";
export const CLIENT_DATE_FORMAT = "dd.MM.yyyy";

export const PROD_URLS = [
  "radio.sapiense.com",
  "profiler.sapiense.com",
  "radio-431c0.web.app",
];

export const FORCE_USE_PROD_ON_DEV = false;
