import {
  _normalizeNames,
  actionHandlers,
  isEqualArrayOfStrings,
  normalizeNameForCross,
} from "../helpers";
import _ from "lodash";

export default actionHandlers(
  {
    NAMES_SNAPSHOT: (state, payload) => {
      // normalize
      let list = _.mapValues(payload, (v, fq) => {
        const normalized = _normalizeNames(v);
        // make shallow check from snapshot to prevent uneccesary rerenderings
        if (!state[fq]) return normalized;
        return isEqualArrayOfStrings(state[fq], normalized)
          ? state[fq]
          : normalized;
      });

      const sortedFqList = _.sortBy(_.keys(list), (fq) => {
        return parseFloat(fq);
      });

      const computed = (() => {
        const nameToFs = {};
        _.forEach(list, (listOfNames, fq) => {
          _.forEach(listOfNames, (n) => {
            if (nameToFs[n]) {
              nameToFs[n].push(fq);
            } else {
              nameToFs[n] = [fq];
            }
          });
        });
        let max = 1;
        _.forEach(nameToFs, (arr) => {
          if (arr.length > max) max = arr.length;
        });
        return {
          nameToFs,
          maxValue: max,
        };
      })();

      return {
        list,
        computed,
        sortedFqList,
        isLoaded: true,
      };
    },
  },
  {
    list: {},
    computed: {},
    cross: [],
    isLoaded: false,
  }
);
