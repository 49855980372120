import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import SignalConnection from "./SignalConnection";
import PasswordChange from "./PasswordChange";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export default () => {
  const profile = useSelector(({ profile }) => profile);
  const settings = useSelector(({ settings }) => settings);

  const [error, setError] = useState(null);

  const saveSettings = async (update) => {
    try {
      await setDoc(doc(db, "settings", profile.email), {
        ...settings,
        ...update,
      });
    } catch (err) {
      setError(err.toString());
    }
  };
  return (
    <div className={"container"}>
      <h1>Налаштування</h1>
      {error && (
        <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
      )}
      <div style={{ margin: "20px 0" }}>
        <div>
          <b>email:</b> {profile.email}
        </div>
        <div>
          <b>id підрозділу:</b> {profile.team}
        </div>
        <div>
          <b>телефон</b> {profile.signalAcc || "відсутній"}
        </div>
      </div>
      <SettingsMap
        profile={profile}
        settings={settings}
        setError={setError}
        saveSettings={saveSettings}
      />
      <PasswordChange />
      {profile.signalAcc && (
        <div>
          <div style={{ marginTop: "10px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(settings.extendedSignalHeader)}
                  onChange={({ target }) =>
                    saveSettings({ extendedSignalHeader: target.checked })
                  }
                />
              }
              size={"large"}
              label="Використовувати розширенну шапку для Signal повідомленнь"
            />
          </div>
          <SignalConnection saveSettings={saveSettings} />
        </div>
      )}
    </div>
  );
};
const SettingsMap = ({ settings, saveSettings }) => {
  const mapCK42prefixes = settings.mapCK42prefixes || {};
  const [x, setX] = useState(mapCK42prefixes.x || "54");
  const [y, setY] = useState(mapCK42prefixes.y || "74");
  useEffect(() => {
    if (mapCK42prefixes.x && mapCK42prefixes.x !== x) setX(mapCK42prefixes.x);
    if (mapCK42prefixes.y && mapCK42prefixes.y !== y) setY(mapCK42prefixes.y);
  }, [mapCK42prefixes]);

  return (
    <>
      <h3>Стандартні префікси для карти</h3>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          label={`X`}
          variant="outlined"
          // type={"number"}
          value={x}
          type={"number"}
          onChange={(ev) => setX(ev.target.value)}
          onBlur={() => saveSettings({ mapCK42prefixes: { x, y } })}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={`Y`}
          variant="outlined"
          // type={"number"}
          value={y}
          type={"number"}
          onChange={(ev) => setY(ev.target.value)}
          onBlur={() => saveSettings({ mapCK42prefixes: { x, y } })}
          InputLabelProps={{ shrink: true }}
        />
      </div>
    </>
  );
};
