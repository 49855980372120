import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { capFirst } from "../../helpers";

const CallSign = ({
  value,
  num,
  isLast,
  isFirst,
  setValue,
  append,
  fq,
  options,
  getOptionDisabled,
  alreadyExist,
}) => {
  const ref = useRef();
  useEffect(() => {
    if (isLast && !isFirst && value === "") {
      ref && ref.current && ref.current.focus();
    }
  }, []);

  return (
    <div className="create-message__callsign">
      <Autocomplete
        disablePortal
        options={options}
        freeSolo
        clearIcon={null}
        getOptionDisabled={getOptionDisabled}
        onChange={(event, newValue) => {
          if (newValue && newValue.label) {
            setValue(capFirst(newValue.label));
          } else if (newValue == null) {
            setValue("");
          }
        }}
        onInputChange={({ target }) => {
          if (typeof target.value === "string") {
            setValue(capFirst(target.value));
          } else if (target.value === 0) {
            setValue("");
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputRef={ref}
              label={`абонент ${num}`}
              variant="outlined"
              size="small"
              value={value}
              inputProps={{
                ...params.inputProps,
                value: value,
                style: {
                  ...params.inputProps.style,
                  color: alreadyExist ? "inherit" : "orange",
                  minWidth: value.length * 7,
                },
              }}
            />
          );
        }}
      />
      {append}
    </div>
  );
};

const CallSigns = React.memo(
  ({ callsigns, setCallsigns, fq }) => {
    const names = useSelector(({ names }) => {
      return names.list[fq] || [];
    });
    const options = names.map((n) => ({ label: n }));

    return (
      <div className="create-message__callsigns">
        <div className="create-message__callsigns-list">
          {callsigns.reduce((acc, value, i) => {
            acc.push(
              <CallSign
                fq={fq}
                key={i}
                value={value}
                num={i + 1}
                isLast={i === callsigns.length - 1}
                isFirst={i === 0}
                options={options}
                getOptionDisabled={(option) => {
                  return callsigns.includes(option.label);
                }}
                alreadyExist={names
                  .map((n) => n.toLowerCase())
                  .includes(value.toLowerCase())}
                setValue={(newValue) => {
                  setCallsigns(
                    callsigns
                      .map((v, I) => {
                        return I === i ? newValue : v;
                      })
                      .filter((v, i) =>
                        i === callsigns.length - 1 ? true : v !== ""
                      )
                  );
                }}
                append={
                  i === callsigns.length - 1 ? (
                    <div className="create-message__callsigns-add">
                      <IconButton
                        disabled={callsigns.some((s) => s.trim() === "")}
                        onClick={() => {
                          setCallsigns([...callsigns, ""]);
                        }}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </div>
                  ) : null
                }
              />
            );
            // if ((i + 1) % 2 === 0) {
            //   acc.push(<br key={Math.random()} />);
            // }
            return acc;
          }, [])}
        </div>
      </div>
    );
  },
  (prev, next) => {
    return prev.callsigns === next.callsigns;
  }
);

export default CallSigns;
