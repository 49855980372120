import { capFirst } from "../../helpers";
import React from "react";
import { __DEV__ } from "../../constants";
import copy from "copy-to-clipboard";

const TYPES_NAMES = {
  STATE: "область",
  DISTRICT: "район",
  CAPITAL_CITY: "столиця",
  CITY: "місто",
  URBAN: "селище міського типу",
  VILLAGE: "село",
  SETTLEMENT: "селище",
};

const LocationRow = ({ loc }) => {
  return (
    <span
      className={"location-row"}
      onClick={
        __DEV__
          ? (ev) => {
              ev.preventDefault();
              copy(JSON.stringify(loc, null, 2));
            }
          : null
      }
    >
      <b>{capFirst(loc.name_ua)}</b> ({TYPES_NAMES[loc.type] || loc.type}){" "}
      {loc.state ? `${capFirst(loc.state)} область` : ""}
      {loc.state ? ", " : ""}
      {loc.district ? `${capFirst(loc.district)} район` : ""}
    </span>
  );
};
export default LocationRow;
