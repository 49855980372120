import Button from "@mui/material/Button";
import React, { useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
const capFirst = (s) =>
  s.length === 0 ? s : s[0].toUpperCase() + s.slice(1).toLowerCase();

const Audio = ({ audio }) => {
  if (audio.length === 0) return null;
  const allDuration =
    audio.length === 1
      ? audio[0].duration
      : audio.reduce((acc, { duration }) => acc + duration, 0);
  let str = `Виділені ${audio.length} записів`;
  if (audio.length === 1) str = `Виділений 1 запис`;
  if (audio.length >= 2 && audio.length <= 4)
    str = `Виділено ${audio.length} записа`;

  return (
    <div className="create-message__audio">
      {str}, загальною тривалістю {(allDuration / 1000).toFixed(2)} сек.
      {/*{audio.map(({ time, hash, transcript }, i) => {*/}
      {/*  return (*/}
      {/*    <div className="create-message__audio-item" key={hash}>*/}
      {/*      {time}*/}
      {/*    </div>*/}
      {/*  );*/}
      {/*})}*/}
    </div>
  );
};

export default Audio;
