import { NavLink, Outlet } from "react-router-dom";
import React from "react";
import Activity from "./parts/Activity";
import Auth from "./parts/Auth";
import IframeCommunication from "./IframeCommunication";
import { shallowEqual, useSelector } from "react-redux";
import { isInIframe } from "../helpers";
import { useMyTeam, useTeam } from "../hooks";
import classNames from "classnames";
import { DB_TYPE } from "../firebase/firebase";
import { __DEV__ } from "../constants";

const App = () => {
  const myTeam = useMyTeam();
  const { namesLoaded, authorised, authLoaded } = useSelector(
    ({ names, profile }) => ({
      namesLoaded: names.isLoaded,
      authorised: profile.authorised,
      authLoaded: profile.loaded,
    }),
    shallowEqual
  );
  const team = useTeam();

  return (
    <div className={"fq-wrapper"}>
      {isInIframe && <IframeCommunication />}
      <div className="tabs fq-nav">
        <div className="fq-nav__tabs">
          <div className="btn-group">
            <NavLink
              to="/"
              className={({ isActive }) =>
                "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
              }
            >
              Частоти
            </NavLink>
            <NavLink
              to="/map"
              className={({ isActive }) =>
                "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
              }
            >
              КонтрБатарея
            </NavLink>
            {/*{__DEV__ && myTeam && (
              <NavLink
                to="/decoder"
                className={({ isActive }) =>
                  "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
                }
              >
                Дешифратор
              </NavLink>
            )}*/}
            {/*{__DEV__ && myTeam && (
              <NavLink
                to="/messages"
                className={({ isActive }) =>
                  "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
                }
              >
                Перехоплення
              </NavLink>
            )}*/}
            <NavLink
              to="/reports"
              className={({ isActive }) =>
                "btn btn-mini btn-" + (isActive ? "primary" : "secondary")
              }
            >
              Звіти
            </NavLink>
          </div>
        </div>
        <div
          className={classNames("fq-nav__auth", {
            "fq-nav__auth--dev": DB_TYPE === "DEV",
          })}
        >
          <Auth />
        </div>
      </div>

      <div className="fq-outlet">
        {!authLoaded && (
          <div className={"container"}>Перевіряємо доступи...</div>
        )}
        {authLoaded && !authorised && <NotAuthorised />}
        {authLoaded &&
          authorised &&
          (namesLoaded ? (
            <Outlet />
          ) : (
            <div className={"container"}>Завантажуємо...</div>
          ))}
      </div>
      <Activity />
    </div>
  );
};

const NotAuthorised = () => {
  return (
    <div className={"container"} style={{ paddingTop: "25px" }}>
      <h1>Тільки для військослужбовців</h1>
      <p>
        Для отримання персонального доступу до сервісу, заповніть{" "}
        <a
          href={"https://forms.gle/rff84pkfv8dY8g619"}
          target={"_blank"}
          style={{ textDecoration: "underline" }}
        >
          форму
        </a>{" "}
        або увійдіть вище в свій аккаунт
      </p>
    </div>
  );
};

export default App;
