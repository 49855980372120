import React from "react";
import NavigateCell from "./NavigateCell";
import Name from "./Name";

const findName = (name, query) => {
  return name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
};
const Row = (props) => {
  const {
    fullFqValue,
    nameToFs,
    names,
    searchValue,
    maxValue,
    activeName,
    onClick,
  } = props;
  return (
    <tr>
      <NavigateCell
        id={fullFqValue}
        // title={formatTooltip(g, rayon)}
        value={fullFqValue}
      />

      <td className={"fq-names-cell"}>
        {names?.map((n, i) => {
          // if (!nameToFs[n]) {
          //   console.log('!', n)
          // }
          return (
            <Name
              key={n}
              isMatchSearchValue={searchValue && findName(n, searchValue)}
              isActiveName={activeName === n}
              isLast={i === names.length - 1}
              i={i}
              n={n}
              maxValue={maxValue}
              nameToFs={nameToFs[n]}
              onClick={onClick}
            />
          );
        })}
      </td>
    </tr>
  );
};
const formatTooltip = (g, rayon) => {
  return [g, rayon].join("\n----\n");
};
export default Row;
