import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { normalizeFqToNum, onCopyClick } from "../../helpers";
import CopyFeedback from "../parts/CopiedTooltip";

const NavigateCell = React.memo(
  ({ value, id, title }) => {
    const navigate = useNavigate();
    const chat_title = useSelector(
      ({ frequencies }) => (frequencies[normalizeFqToNum(id)] || {}).chat_title
    );
    const feedbackRef = useRef();
    return (
      <th
        onClick={() => navigate("/fq/" + id)}
        // title={title}
        className="fq-navigate-cell"
      >
        <span>{value}</span>
        {chat_title && <i>{chat_title}</i>}
        <div
          title={"Копіювати шапку для чату"}
          className="fq-navigate-cell__button"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            onCopyClick({
              fq: id,
              chat_title,
            });
            feedbackRef.current.show();
          }}
        >
          <CopyFeedback ref={feedbackRef} />
        </div>
      </th>
    );
  },
  () => true
);

export default NavigateCell;
