import Message from "../parts/Message";
import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useWriteAccess } from "../../hooks";
import { useSelector } from "react-redux";
import { reduceArrayOfStringToForms } from "../../semantic";
import startOfDay from "date-fns/startOfDay";

const Messages = () => {
  const team = useSelector(({ profile }) => {
    return profile.team;
  });
  const messages = useSelector(({ messages }) => {
    return messages;
  });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "messages"),
        where("team", "==", team)
        // where("createdBy", ">", startOfDay(new Date()) - 1000 * 60 * 60 * 24)
      ),
      (res) => {
        const data = res.docs.map((v) => v.data());

        if (data.length) {
          store.dispatch({
            type: "MESSAGES_SNAPSHOT",
            payload: {
              data,
            },
          });
        }
        setLoaded(true);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <div>
      <pre>{JSON.stringify(messages, null, 2)}</pre>
    </div>
  );
};

export default Messages;
