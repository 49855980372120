import { useEffect, useState } from "react";
import { __DEV__ } from "../../constants";
import _ from "lodash";

let manifest = null;
const Updater = () => {
  const [updated, setUpdated] = useState(__DEV__ ? true : null);

  useEffect(() => {
    let interval;
    const fetchManifest = async () => {
      try {
        const res = await fetch(
          "/asset-manifest.json?" + new Date().valueOf(),
          { cache: "no-store" }
        );
        const newJSON = await res.json();
        const valid =
          _.isPlainObject(newJSON) && _.isArray(newJSON.entrypoints);
        if (!valid) return;
        if (manifest === null) {
          manifest = newJSON;
          return;
        }
        if (_.isEqual(manifest.entrypoints, newJSON.entrypoints)) return;
        setUpdated(true);
        clearInterval(interval);
      } catch (err) {
        console.log("[updater]", err);
      }
    };
    interval = setInterval(fetchManifest, 1000 * 60);
    return () => {
      console.log("unmounted");
      clearInterval(interval);
    };
  }, []);

  return updated ? (
    <div className="updater">
      <span>
        Наявне оновлення, потрібно{" "}
        <a href="#" onClick={() => window.location.reload()}>
          перезавантажити сторінку
        </a>
        . Ваші зміни збережені.
      </span>
    </div>
  ) : null;
};
export default () => (__DEV__ ? null : <Updater />);
