import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import startOfDay from "date-fns/startOfDay";
import React, { useCallback, useState } from "react";
import { useTeam } from "../../hooks";
import isSameDay from "date-fns/isSameDay";
import formatDate from "date-fns/format";
import endOfDay from "date-fns/endOfDay";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { capFirst, normalizeFq, prepareFactionsForSearch } from "../../helpers";
import Alert from "@mui/material/Alert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

const joinArrayOrEmpty = (arr) =>
  _.isArray(arr) && arr.length > 0 ? arr.join(", ") : "";
const oneDayMs = 1000 * 60 * 60 * 24;

const getDayCenter = (d) => startOfDay(d).valueOf() + oneDayMs / 2;

const DayTable = ({ possibleToWorkWithReports }) => {
  const frequencies = useSelector(({ frequencies }) => frequencies);
  const yesterday = getDayCenter(new Date().valueOf() - oneDayMs);

  const [fromDatetime, setFromDatetime] = useState(yesterday);
  const [toDatetime, setToDatetime] = useState(yesterday);

  const [length, setLength] = useState(null);
  const [error, setError] = useState(null);
  let factions = useSelector(({ factions }) => factions);
  const user_uid = useSelector(({ profile }) => profile.uid);
  const dispatch = useDispatch();
  const [allTeam, setAllTeam] = useState(false);

  const team = useTeam();

  const requestReport = async (format) => {
    setLength(null);
    try {
      let formattedDate;

      if (isSameDay(fromDatetime, toDatetime)) {
        formattedDate = formatDate(fromDatetime, "dd.MM.yyyy");
      } else {
        formattedDate =
          formatDate(fromDatetime, "dd.MM.yyyy") +
          " - " +
          formatDate(toDatetime, "dd.MM.yyyy");
      }

      console.log("requestReport", {
        format,
        formattedDate,
        datetime: new Date(fromDatetime),
        from: startOfDay(fromDatetime),
        to: endOfDay(toDatetime),
        same: isSameDay(fromDatetime, toDatetime),
      });

      const queryArr = [
        collection(db, "messages"),
        where("team", "==", team),
        where("datetime", ">", startOfDay(fromDatetime).valueOf()),
        where("datetime", "<", endOfDay(toDatetime).valueOf()),
      ];
      // console.log(123, { allTeam });
      if (!allTeam) {
        queryArr.push(where("createdBy", "==", user_uid));
      }
      // console.log(123, queryArr);

      const res = await getDocs(query(...queryArr));

      // console.log(234, res);
      let results = [];
      res.forEach((v) => {
        const data = v.data();
        results.push(data);
      });
      results = _.sortBy(results, ["datetime", "frequency"]);

      setLength(results.length);

      if (results.length === 0) {
        return;
      }

      const locationsToDownload = _.reduce(
        results,
        (acc, { locations }) => {
          if (_.isArray(locations) && locations.length > 0) {
            locations.forEach((locId) => {
              if (acc.indexOf(locId) === -1) {
                acc.push(locId);
              }
            });
          }
          return acc;
        },
        []
      );
      const factionsToDownload = _.reduce(
        results,
        (acc, { factions }) => {
          if (_.isArray(factions) && factions.length > 0) {
            factions.forEach((facId) => {
              if (acc.indexOf(facId) === -1) {
                acc.push(facId);
              }
            });
          }
          return acc;
        },
        []
      );

      // console.log({
      //   results,
      //   locationsToDownload,
      //   factionsToDownload,
      // });

      const locQuery = await __firebase.queryOptions({
        type: "locations",
        list: locationsToDownload,
      });
      const locationsResult = _.keyBy(locQuery.data, "id");
      missedFromLastUpdate.forEach((l) => {
        locationsResult[l.id] = l;
      });

      if (_.isEmpty(factions)) {
        await getDoc(doc(db, "dictionaries", "factions")).then((docSnap) => {
          factions = docSnap.data();
          dispatch({
            type: "SET_FACTIONS",
            payload: factions,
          });
        });
      }
      const factionsResult = _.pick(
        _.keyBy(prepareFactionsForSearch(factions, false), "uuid"),
        factionsToDownload
      );

      // console.log({
      //   locationsResult,
      //   factionsResult,
      // });

      const preparedData = results.map(
        ({
          frequency,
          text,
          comment,
          datetime,
          factions,
          locations,
          callsigns,
        }) => {
          return {
            ["Дата"]: formatDate(datetime, "dd.MM.yyyy"),
            ["Час"]: formatDate(datetime, "HH:mm"),
            ["Частота"]: normalizeFq(frequency),
            ["Назва радіомережі"]: frequencies[frequency]
              ? frequencies[frequency].chat_title
              : "",
            ["Противник"]: joinArrayOrEmpty(
              (factions || []).map((id) => {
                const faction = factionsResult[id];
                return faction
                  ? faction.name
                  : `[не знайдено підрозділ з id: ${id}]`;
              })
            ),
            ["Район"]: joinArrayOrEmpty(
              (locations || []).map((id) => {
                const location = locationsResult[id];
                return location
                  ? capFirst(location.name_ua)
                  : `[не знайдено локацію з id: ${id}]`;
              })
            ),
            ["Абоненти"]: joinArrayOrEmpty(callsigns).toUpperCase(),
            ["Зміст"]: text,
            ["Примітка"]: comment,
          };
        }
      );

      if (format === "doc") {
        exportToDoc(
          preparedData,
          `ПЕРЕХОПЛЕННЯ ${team.toUpperCase()} ${formattedDate}`
        );
      } else {
        const worksheet = XLSX.utils.json_to_sheet(preparedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `${team}_${formattedDate}`
        );
        XLSX.writeFile(
          workbook,
          `ПЕРЕХОПЛЕННЯ ${team.toUpperCase()} ${formattedDate}.${format}`,
          { compression: true }
        );
      }
    } catch (err) {
      console.error(err);
      setError(err.toString());
    }
  };

  const requestReportXlsx = useCallback(
    () => requestReport("xlsx"),
    [fromDatetime, toDatetime, allTeam]
  );
  const requestReportCsv = useCallback(
    () => requestReport("csv"),
    [fromDatetime, toDatetime, allTeam]
  );
  const requestReportDoc = useCallback(
    () => requestReport("doc"),
    [fromDatetime, toDatetime, allTeam]
  );

  const requestAllTeamChange = useCallback((ev) => {
    setAllTeam(ev.target.checked);
  }, []);

  return (
    <>
      {error && (
        <div className="row">
          <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
        </div>
      )}
      {!possibleToWorkWithReports && (
        <div className="row">
          <Alert severity="warning">
            Скачування звітів виконується на{" "}
            <a href="https://profiler.sapiense.com/reports" target={"_blank"}>
              окремій сторінці
            </a>
          </Alert>
        </div>
      )}
      <div className="row">
        <div className="col">
          1. Вигрузка повідомлень за день в табличному форматі
        </div>
        <div className="col">
          <DatePicker
            disabled={!possibleToWorkWithReports}
            label="Дата початку"
            value={new Date(fromDatetime)}
            onChange={(newValue) => {
              setFromDatetime(getDayCenter(newValue.valueOf()));
            }}
            // componentsProps={{ OpenPickerButton: { tabIndex: -1 } }}
            // slotProps={{ OpenPickerButton: { tabIndex: -1 } }}
            // tabIndex={-1}
            disableFuture
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  size="small"
                  inputProps={{ ...props.inputProps, tabIndex: -1 }}
                  variant="standard"
                  InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
                />
              );
            }}
          />
          <br />
          <br />
          <DatePicker
            disabled={!possibleToWorkWithReports}
            label="Дата кінця"
            value={new Date(toDatetime)}
            onChange={(newValue) => {
              setToDatetime(getDayCenter(newValue.valueOf()));
            }}
            // componentsProps={{ OpenPickerButton: { tabIndex: -1 } }}
            // slotProps={{ OpenPickerButton: { tabIndex: -1 } }}
            // tabIndex={-1}
            disableFuture
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  size="small"
                  inputProps={{ ...props.inputProps, tabIndex: -1 }}
                  variant="standard"
                  InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
                />
              );
            }}
          />
          <div>
            <FormControlLabel
              control={
                <Switch
                  disabled={!possibleToWorkWithReports}
                  checked={allTeam}
                  onChange={requestAllTeamChange}
                />
              }
              size={"small"}
              label="По всьому підрозділу"
            />
          </div>
        </div>
        <div className="col">
          <div className="row">
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                variant={"contained"}
                onClick={requestReportXlsx}
                disabled={!possibleToWorkWithReports}
              >
                .xlsx
              </Button>

              <Button
                variant={"contained"}
                onClick={requestReportCsv}
                disabled={!possibleToWorkWithReports}
              >
                .csv
              </Button>
              <Button
                variant={"contained"}
                onClick={requestReportDoc}
                disabled={!possibleToWorkWithReports}
              >
                .doc
              </Button>
            </ButtonGroup>
          </div>
          {typeof length === "number" && (
            <div className="row">
              {length === 0 && <Alert severity="warning">Немає даних</Alert>}
              {length !== 0 && (
                <i>Знайдено {length} повідомлень, упаковуємо в файл</i>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const exportToDoc = (preparedData, title) => {
  const {
    Document,
    Table,
    TableRow,
    TableCell,
    TextRun,
    Paragraph,
    Packer,
    AlignmentType,
    HeadingLevel,
    ShadingType,
    convertMillimetersToTwip,
    WidthType,
  } = docx;

  // docx has poor cross-support for tables in MSWord and Google Docs,
  // so need to duplicate column widths in table cells and in table def
  // Дата - Час - Хто - Кому - Частота - Зміст - Примітка
  const columnWidths = [
    convertMillimetersToTwip(20),
    convertMillimetersToTwip(15),
    convertMillimetersToTwip(25),
    convertMillimetersToTwip(25),
    convertMillimetersToTwip(20),
    convertMillimetersToTwip(60),
    convertMillimetersToTwip(25),
  ];

  const renderSectionHeader = (title) => {
    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: title,
              heading: HeadingLevel.HEADING_3,
            }),
          ],
          shading: {
            fill: "ffff00",
            type: ShadingType.SOLID,
            color: "ffff00",
          },
          columnSpan: 7,
          margins: {
            top: convertMillimetersToTwip(1),
            bottom: convertMillimetersToTwip(1),
          },
        }),
      ],
    });
  };

  const renderSectionSubheader = () => {
    const shading = {
      fill: "87ca46",
      type: ShadingType.SOLID,
      color: "87ca46",
    };
    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Дата", bold: true })],
            }),
          ],
          width: { size: columnWidths[0], type: WidthType.DXA },
          shading,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Час", bold: true })],
            }),
          ],
          width: { size: columnWidths[1], type: WidthType.DXA },
          shading,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Хто", bold: true })],
            }),
          ],
          width: { size: columnWidths[2], type: WidthType.DXA },
          shading,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Кому", bold: true })],
            }),
          ],
          width: { size: columnWidths[3], type: WidthType.DXA },
          shading,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Частота", bold: true })],
            }),
          ],
          width: { size: columnWidths[4], type: WidthType.DXA },
          shading,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Зміст", bold: true })],
            }),
          ],
          width: { size: columnWidths[5], type: WidthType.DXA },
          shading,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: "Примітка", bold: true })],
            }),
          ],
          width: { size: columnWidths[6], type: WidthType.DXA },
          shading,
        }),
      ],
    });
  };

  const renderSectionRow = (row) => {
    const date = row["Дата"].substring(0, 6) + row["Дата"].substring(8, 10);
    const abonents = row["Абоненти"].split(",").map((n) => n.trim());
    const who = abonents[0] || "";
    const whom = abonents.slice(1).join(", ") || "";
    const margins = {
      top: convertMillimetersToTwip(1),
      bottom: convertMillimetersToTwip(1),
      left: convertMillimetersToTwip(2),
      right: convertMillimetersToTwip(2),
    };

    return new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph(date)],
          margins,
        }),
        new TableCell({
          children: [new Paragraph(row["Час"])],
          margins,
        }),
        new TableCell({
          children: [new Paragraph(who)],
          margins,
        }),
        new TableCell({
          children: [new Paragraph(whom)],
          margins,
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: row["Частота"], bold: true })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          margins,
        }),
        new TableCell({
          children: [
            new Paragraph({
              // Each new line is separate TextRun with break: 1
              children: row["Зміст"]
                .split("\n")
                .map(
                  (l, i) => new TextRun({ break: i === 0 ? 0 : 1, text: l })
                ),
            }),
          ],
          margins,
        }),
        new TableCell({
          children: [new Paragraph(row["Примітка"])],
          margins,
        }),
      ],
    });
  };

  const data = _.groupBy(preparedData, "Назва радіомережі");

  const rows = [];
  Object.keys(data).forEach((key) => {
    const messages = data[key];
    rows.push(renderSectionHeader(key));
    rows.push(renderSectionSubheader());
    messages.forEach((message) => {
      rows.push(renderSectionRow(message));
    });
  });

  const table = new Table({
    rows,
    columns: 7,
    columnWidths,
  });

  const doc = new Document({
    title,
    styles: {
      default: {
        heading3: {
          run: {
            size: 24,
            bold: true,
            color: "000000",
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
      },
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: convertMillimetersToTwip(20),
              right: convertMillimetersToTwip(10),
              bottom: convertMillimetersToTwip(20),
              left: convertMillimetersToTwip(10),
            },
          },
        },
        children: [table],
      },
    ],
  });

  Packer.toBlob(doc)
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = `${title}.doc`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {
      console.error("Error downloading file");
    });
};

const missedFromLastUpdate = [
  {
    name_ru: "ямполевка",
    lng: "37.95544",
    lat: "49.07146",
    type: "VILLAGE",
    district: "краматорський",
    state: "донецька",
    name_ua: "ямполівка",
    id: "c3f73c24-a4ff-4251-8175-5b6c5b5bb11f",
  },
];

export default DayTable;
