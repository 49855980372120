import { doc, getDoc, onSnapshot } from "firebase/firestore";
import store from "../store";
import { signOut } from "firebase/auth";
import _ from "lodash";

export default (auth, db) => {
  auth.onAuthStateChanged(async (user) => {
    let unsubscribe = () => null;

    if (user) {
      // console.log(user);
      let payload = {
        email: user.email,
        uid: user.uid,
        team: "osb49",
        access: "reader",
      };
      let extra;
      try {
        extra = await getDoc(doc(db, "users", user.email));
      } catch (err) {
        alert(
          "Помилка доступу. Зверніться до адміністратору щоб вам надали права"
        );
        store.dispatch({
          type: "USER_AUTH_EMPTY",
        });
        await signOut(auth);
        return;
      }

      const extraData = extra.data();

      unsubscribe = onSnapshot(doc(db, "users", user.email), (res) => {
        const data = res.data();
        if (!_.isEqual(_.pick(store.getState().profile, _.keys(data)), data)) {
          // console.log("changed!!!");
          store.dispatch({
            type: "USER_AUTH_UPDATED",
            payload: data,
          });
        }
      });

      if (extra.exists()) {
        payload = {
          ...payload,
          ...extraData,
        };
        if (!extraData.team) {
          payload.access = "reader";
        }
      }

      store.dispatch({
        type: "USER_AUTH_SUCCESS",
        payload,
      });
    } else {
      unsubscribe();
      store.dispatch({
        type: "USER_AUTH_EMPTY",
      });
    }
  });
};
