import _ from "lodash";

export const parseKBB = (str) => {
  let lines = str
    .replaceAll(",", "\n")
    .replaceAll(";", "\n")
    .replaceAll(" ", "\n")
    .split("\n");
  lines = lines.slice(1);
  if (lines.length === 0) return [];

  return lines.reduce((acc, l) => {
    l = l.trim();
    let m = l.match(/[0-9]{5}/gm);
    if (!_.isArray(m)) return acc;
    if (m.length === 1) m = m[0];
    if (m.length === 5) {
      acc.push(m);
    }
    return acc;
  }, []);
};
