import { initializeApp } from "firebase/app";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  collection,
  query,
  where,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
import store from "../store";
import firebaseConfigTest from "./test-creds.json";
import firebaseConfig from "./prod-creds.json";
import firebaseAuth from "./firebaseAuth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { __DEV__, PROD_URLS, FORCE_USE_PROD_ON_DEV } from "../constants";
import { groupChangesFromCollectionSnapshot } from "../helpers";

const CONFIGS = {
  PROD: firebaseConfig,
  DEV: firebaseConfigTest,
};
const isProdUrl = PROD_URLS.includes(window.location.hostname);
let CONF = isProdUrl ? "PROD" : "DEV";
if (__DEV__ && FORCE_USE_PROD_ON_DEV) {
  CONF = "PROD";
}
export const DB_TYPE = CONF;
if (!isProdUrl) {
  console.log("FIRESTORE initialize with", CONF);
}
// Initialize Firebase
const app = initializeApp(CONFIGS[CONF]);

export const db = getFirestore(app);

export const auth = getAuth();
const functions = getFunctions(app, "europe-west3");

const proxySignal = httpsCallable(functions, "proxySignal");
const signalApi = httpsCallable(functions, "signalApi");
const queryOptions = httpsCallable(functions, "queryOptions");

// const proxySignalWrapped = async (...args) => {
//   console.log("[proxySignal] calling with", ...args);
//   const before = new Date().valueOf();
//   const result = await proxySignal(...args);
//   const after = new Date().valueOf();
//   console.log(`[proxySignal] result in ${(after - before) / 1000}s`, result);
//   return result;
// };
const signalApiWrapped = async (...args) => {
  console.log("[signalApi] calling with", ...args);
  const before = new Date().valueOf();
  const result = await signalApi(...args);
  const after = new Date().valueOf();
  console.log(`[signalApi] result in ${(after - before) / 1000}s`, result);
  return result;
};
window.__firebase = {
  app,
  db,
  auth,
  functions,
  proxySignal,
  signalApi: __DEV__ ? signalApiWrapped : signalApi,
  queryOptions,
};
// --------------------------------------------------------------

export const subscribeCommonData = ({ dispatch, team, email }) => {
  onSnapshot(doc(db, "names", team), (names) => {
    dispatch({
      type: "NAMES_SNAPSHOT",
      payload: _.pickBy(names.data(), (value, key) => value !== "") || {},
    });
  });
  onSnapshot(doc(db, "frequencies", team), (frequencies) => {
    dispatch({
      type: "FREQUENCIES_SNAPSHOT",
      payload: frequencies.data() || {},
    });
  });
  onSnapshot(doc(db, "settings", email), (settings) => {
    const s = settings.data();
    if (_.isObjectLike(s) && !_.isEmpty(s)) {
      dispatch({
        type: "SETTINGS_SNAPSHOT",
        payload: s,
      });
    }
  });

  onSnapshot(
    query(collection(db, "fqmeta"), where("team", "==", team)),
    (input) => {
      const { toUpdate, toRemove } = groupChangesFromCollectionSnapshot(
        input,
        "fq"
      );

      if (!_.isEmpty(toUpdate)) {
        dispatch({
          type: "FQMETA_UPDATE",
          payload: toUpdate,
        });
      }
      if (!_.isEmpty(toRemove)) {
        dispatch({
          type: "FQMETA_REMOVE",
          payload: _.keys(toRemove),
        });
      }
    }
  );
};
firebaseAuth(auth, db);
