import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import { capFirst, normalizeFq } from "../helpers";
import LocationRow from "./parts/LocationRow";
import Map from "./Map/Map";
import React from "react";
import copy from "copy-to-clipboard";

export default () => {
  const { locationID } = useParams();
  let location = null;
  const freqList = useSelector(({ fqmeta }) => {
    return _.pickBy(fqmeta, ({ locations }) => {
      if (!_.isArray(locations)) return false;
      const found = locations.find((loc) => loc.id === locationID);
      if (location === null && found) location = found;
      return Boolean(found);
    });
  });
  return (
    <div className={"container"}>
      <h1 onClick={() => copy(JSON.stringify(location, null, 2))}>
        {location ? capFirst(location.name_ua) : locationID}
      </h1>

      <div className="row">
        <div className="col-sm">
          {location && <LocationRow loc={location} />}
          <ul>
            {_.map(freqList, (fqmeta, fq) => {
              return (
                <li key={fq}>
                  <NavLink to={"/fq/" + fq}>{normalizeFq(fq)}</NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        {location && location.lat && location.lng && (
          <div className="col-sm" style={{ maxHeight: "500px" }}>
            <Map
              objects={[
                {
                  type: "point",
                  key: location.id,
                  value: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng),
                  },
                  label: capFirst(location.name_ua),
                },
              ]}
              center={"auto"}
              zoom={12}
            />
          </div>
        )}
      </div>
    </div>
  );
};
