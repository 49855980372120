import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "./firebase/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";

// --------------------------------------------------------------
const CANARY_TEAMS = ["osb49", "ministryofmagic"];
export const useMyTeam = () => useTeam() === "osb49";
export const useCanaryTeam = () => CANARY_TEAMS.indexOf(useTeam()) !== -1;

// --------------------------------------------------------------
export const useWriteAccess = () => {
  const access = useSelector(({ profile }) => profile.access);
  return access === "writer" || access === "admin";
};
export const useTeam = () => {
  return useSelector(({ profile }) => profile.team);
};
export const useAuthorised = () => {
  return useSelector(({ profile }) => profile.authorised);
};

// --------------------------------------------------------------
export const useDocs = (s, options, { selector, transform }) => {
  options = _.defaults({}, options, {
    initialValue: [],
  });
  let args = [];
  const team = useTeam();
  const cache = useSelector(selector);

  args = [collection(db, s.collection), where("team", "==", team)];
  if (_.isArray(s.where)) {
    if (s.where.length === 3 && typeof s.where[1] === "string") {
      args.push(where(...s.where));
    } else {
      s.where.forEach((rules) => {
        if (rules.length === 3 && typeof rules[1] === "string") {
          args.push(where(...rules));
        }
      });
    }
  }
  if (_.isArray(s.orderBy)) {
    if (s.orderBy[1] === "asc" || s.orderBy[1] === "desc") {
      args.push(orderBy(...s.orderBy));
    } else {
      s.orderBy.forEach((rules) => {
        if (rules[1] === "asc" || rules[1] === "desc") {
          args.push(orderBy(...rules));
        }
      });
    }
  }
  if (_.isNumber(s.limit)) {
    args.push(limit(s.limit));
  }
  const [storedValue, loading, error, snapshot] = useCollectionData(
    query(...args),
    options
  );
  snapshot && console.log(snapshot.docChanges());

  const dispatch = useDispatch();
  useEffect(() => {
    if (loading || error) return;
    // TODO: get changes from snapshot, not value
    const newDocs = transform ? transform(storedValue) : value;
    dispatch({
      type: "DOCS_CACHE",
      payload: {
        collection: s.collection,
        docs: newDocs,
      },
    });
  }, [storedValue]);

  let value = loading
    ? cache
    : transform
    ? transform(storedValue)
    : storedValue;

  return [value, loading, error];
};

// --------------------------------------------------------------

export function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute("data-status", "loading");
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          );
        };
        script.addEventListener("load", setAttributeFromEvent);
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };
      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
}

// --------------------------------------------------------------
export const useCustomFaction = (fq) => {
  return useSelector(({ frequencies }) =>
    frequencies[fq]
      ? frequencies[fq].custom_faction || frequencies[fq].chat_title
      : ""
  );
};
