import classnames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import React from "react";

const Publish = ({
  whatsapp,
  groups,
  selectedGroups,
  toggleGroup,
  defaultGroups,
  draftExists,
}) => {
  console.log("whatsapp", whatsapp);
  const PublishItem = ({ name, id, isWhatsapp }) => {
    const publish = selectedGroups[id];

    return (
      <div
        className={classnames("create-message__group", {
          "create-message__group_disabled": !draftExists,
        })}
        key={id}
      >
        <div className={"create-message__grouptitle"}>
          <Checkbox
            checked={(draftExists ? publish : defaultGroups[id]) || false}
            className={"create-message__groupcheck"}
            id={`group[${id}]`}
            onChange={() => toggleGroup(id)}
            color={isWhatsapp ? "success" : "info"}
          />
          <label
            className={"create-message__groupname"}
            htmlFor={`group[${id}]`}
          >
            {name}
          </label>
        </div>
      </div>
    );
  };
  return (
    <div className="create-message__groups">
      {_.map(groups, ({ name, note, id }) => {
        return PublishItem({ name, id, isWhatsapp: false });
      })}
      {_.map(whatsapp, (id, name) => {
        return PublishItem({ name, id, isWhatsapp: true });
      })}
    </div>
  );
};
export default Publish;
