import React, { useEffect, useState } from "react";
import "../index.css";
import { useParams } from "react-router-dom";

import { isInIframe, normalizeFq, normalizeFqToNum } from "../helpers";
import NamesTab from "./FQ/NamesTab";
import InfoTab from "./FQ/InfoTab";
import { shallowEqual, useSelector } from "react-redux";
import FgField from "./parts/FgField";
import ChatTitle from "./parts/ChatTitle";
import NoFQData from "./parts/NoFQData";
import MessagesTab from "./MessagesTab/MessagesTab";
import FqLocation from "./FQ/FqLocation";
import _ from "lodash";

const notInIframe = !isInIframe;
const FQ = () => {
  const { fqID } = useParams();
  const fq = parseFloat(fqID);

  const [activeTab, setActiveTab] = useState(isInIframe ? "messages" : "names");

  const { frequency, names, fqmeta, draft } = useSelector(
    ({ names, frequencies, fqmeta, drafts }) => {
      return {
        frequency: frequencies[fq],
        fqmeta: fqmeta[fq],
        names: names.list[fq] || [],
        draft: drafts[fq],
      };
    },
    shallowEqual
  );

  // --------------------------------------------------------------
  const FQInfo =
    frequency && Object.values(frequency).join("").trim().length > 0;
  const [allowEditFQ, setAllowEditFQ] = useState(notInIframe);
  const showFqTabs = Boolean(
    allowEditFQ ||
      names.length ||
      FQInfo ||
      _.isPlainObject(fqmeta) ||
      _.isPlainObject(draft)
  );
  const setFQEdit = () => {
    setAllowEditFQ(true);
  };

  useEffect(() => {
    if (isInIframe && allowEditFQ) {
      setAllowEditFQ(false);
    }
  }, [fqID]);

  // --------------------------------------------------------------

  const tabsHeaders = [
    {
      title: "Перехоплення",
      tab: "messages",
    },
    {
      title: "Абоненти",
      tab: "names",
    },
    {
      title: "Про частоту",
      tab: "info",
    },
    {
      title: "На мапі",
      tab: "map",
    },
  ];

  // todo: extract tabs to separate components  (FQInfo, FQHistory, FQNames, FQMessages)  and use them here as children of FQ component
  const TABS = {
    history: (
      <div>
        <FgField
          fq={fq}
          title={"Історія"}
          name={"history"}
          frequency={frequency}
        />
      </div>
    ),
    names: <NamesTab fqID={fqID} />,
    messages: <MessagesTab fqID={fqID} />,
    info: <InfoTab fq={fq} frequency={frequency} />,
    map: <FqLocation fq={fq} />,
  };

  return (
    <div className="fqview-container" key={fq}>
      {!showFqTabs ? (
        <NoFQData fq={fq} callback={setFQEdit} />
      ) : (
        <>
          <div className="fqview-topcontainer">
            <div className="fqview-topcontainer__name">
              <h1>{normalizeFq(fqID)}</h1>
            </div>
            <div className="fqview-topcontainer__chattitle">
              <ChatTitle fq={normalizeFqToNum(fqID)} frequency={frequency} />
            </div>
          </div>
          <div
            className="nav nav-tabs fqview-tabs"
            id="nav-tab"
            role="tablist"
            style={{ paddingLeft: "20px" }}
          >
            {tabsHeaders.map(({ title, tab }) => {
              return (
                <button
                  key={tab}
                  className={"nav-link " + (activeTab === tab ? "active" : "")}
                  id="nav-home-tab"
                  type="button"
                  role="tab"
                  onClick={() => setActiveTab(tab)}
                >
                  {title}
                </button>
              );
            })}
          </div>
          <div className="fqview-tabcontainer">{TABS[activeTab]}</div>
        </>
      )}
    </div>
  );
};

export default FQ;
