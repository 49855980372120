import proj4 from "proj4";
import _ from "lodash";
import { fromLatLon, toLatLon } from "utm";

export const convertCK42ToWGS84 = (() => {
  proj4.defs(
    "EPSG:28406",
    "+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,-0.35,-0.79,-0.22 +units=m +no_defs +type=crs"
  );
  proj4.defs(
    "EPSG:28407",
    "+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,-0.35,-0.79,-0.22 +units=m +no_defs +type=crs"
  );
  proj4.defs(
    "EPSG:28405",
    "+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,-0.35,-0.79,-0.22 +units=m +no_defs +type=crs"
  );

  return ({ x, y, zone }) => {
    const result = proj4(`EPSG:2840${zone}`).inverse({
      x: _.toNumber(y),
      y: _.toNumber(x),
    });

    return _.mapValues({ lat: result.y, lng: result.x }, _.toString);
  };
})();

export const convertWGS84ToUTM = ({ lat, lng }) => {
  return _.mapValues(fromLatLon(lat, lng), _.toString);
};

export const convertUTMToWGS84 = ({
  easting,
  northing,
  zoneNum = 37,
  zoneLetter,
  northern,
  strict,
}) => {
  return _(toLatLon(easting, northing, zoneNum, zoneLetter, northern, strict))
    .mapValues(_.toString)
    .mapValues((s) => s.replace(",", "."))
    .mapKeys((v, k) => ({ latitude: "lat", longitude: "lng" }[k]))
    .value();
};
export const wgs84ToSk42 = (() => {
  const SK42_PROJ = "EPSG:4284";
  const WGS84_PROJ = "EPSG:4326";
  const UCS2000_PROJ = "EPSG:5565";
  // proj4.defs(SK42_PROJ, "+proj=longlat +ellps=krass +towgs84=25,-141,-78.5,0,-0.35,-0.736,0 +no_defs +type=crs"); // EPSG
  proj4.defs(
    SK42_PROJ,
    "+proj=longlat +ellps=PZ90 +towgs84=23.92,-141.27,-80.9,0,-0.35,-0.82,-0.12,0 +no_defs +type=crs"
  ); // russia SK42
  proj4.defs(
    UCS2000_PROJ,
    "+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=24,-121,-76,0,0,0,0 +units=m +no_defs +type=crs"
  );

  /**
   * Converts given coordinates in WGS84 to SK42.
   * @param {WGS84} coordinates coordinates in WGS84 encoding
   * @return {SK42} coordinates in SK42 encoding
   */
  // function wgs84ToSk42({ lat, lng }) {
  //   const converted = proj4(
  //     WGS84_PROJ,
  //     UCS2000_PROJ,
  //     [lat, lng].map(parseFloat)
  //   );
  //   return {
  //     x: converted[0],
  //     y: converted[1],
  //     zone: 7,
  //   };
  // }
  function wgs84ToUcs2000({ lat, lng }) {
    const converted = proj4(UCS2000_PROJ).forward([lng, lat].map(parseFloat));
    return { x: converted[1], y: converted[0], zone: 7 };
  }

  return (...args) => _.mapValues(wgs84ToUcs2000(...args), (n) => n.toFixed(0));
})();

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function calcDistance(lat1, lon1, lat2, lon2, unit = "K") {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}
