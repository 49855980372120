import { actionHandlers } from "../helpers";
import _ from "lodash";

export default actionHandlers(
  {
    ["SET_FACTIONS"]: (state, payload) => {
      return payload;
    },
  },
  {}
);
