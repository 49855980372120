import React from "react";

class CopyFeedback extends React.Component {
  state = {
    show: false,
  };
  show = () => {
    this.setState(
      {
        show: true,
      },
      () => {
        setTimeout(() => {
          try {
            this.setState({ show: false });
          } catch (err) {}
        }, 2500);
      }
    );
  };
  render() {
    if (!this.state.show) return null;

    return <div className="copy-feedback">скопійовано ✓</div>;
  }
}

export default CopyFeedback;
