import { actionHandlers } from "../helpers";

export const getDefault = (override) => {
  const def = {
    text: "",
    comment: "",
    callsigns: [""],
    tags: [],
    audio: [],
    datetime: new Date().valueOf(),
    selectedGroups: {},
  };
  if (_.isPlainObject(override) && !_.isEmpty(override)) {
    return {
      ...def,
      ...override,
    };
  } else {
    return def;
  }
};
export default actionHandlers(
  {
    PLAYER_SELECTED_CHANGE: (state, payload) => {
      const removedFq = _(state)
        .pickBy((v, k) => payload[k] == null && v.audio.length > 0)
        .mapValues((v) => ({
          ...v,
          audio: [],
        }))
        .value();

      return {
        ...state,
        ...removedFq,
        ..._.mapValues(payload, (listOfAudio, fq) => {
          return {
            ...(state[fq] || getDefault()),
            audio: listOfAudio,
            datetime: listOfAudio[0].time,
          };
        }),
      };
    },
    EDIT_DRAFT: (state, { fq, update }) => {
      if (update === null) {
        return _.omit(state, fq);
      }
      return {
        ...state,
        [fq]: {
          ...getDefault(),
          ...state[fq],
          ...update,
        },
      };
    },
    CLEAN_DRAFTS: () => ({}),
    USER_LOGOUT_SUCCESS: () => ({}),
    USER_AUTH_EMPTY: () => ({}),
  },
  {}
);
