import _ from "lodash";
import store from "../../store";

export const transformToBase64 = (data) => {
  const u8list = _.reduce(
    data,
    (acc, v, k) => {
      acc[k] = v;
      return acc;
    },
    []
  );
  let b64encoded = btoa(
    [].reduce.call(
      new Uint8Array(u8list),
      (p, c) => {
        return p + String.fromCharCode(c);
      },
      ""
    )
  );
  let mimetype = "image/png";
  return "data:" + mimetype + ";base64," + b64encoded;
};

export const fetchSignalGroups = () =>
  new Promise((resolve, reject) => {
    const { signalAcc } = store.getState().profile;
    if (!signalAcc) return;

    __firebase
      .signalApi({
        type: "groups",
      })
      .then(({ data }) => {
        if (data == null) return reject(data);
        if (_.has(data, "error")) return reject(data);
        resolve(data);
      });
  });

export const fetchQRCode = () =>
  new Promise((res, rej) => {
    __firebase
      .signalApi({
        type: "qrcode",
      })
      .then((result) => {
        if (result.data == null || _.has(result.data, "error")) {
          rej(
            "Виникла помилка при отриманні QR-коду. Сповістіть про це адміністратора та спробуйте пізніше."
          );
        } else if (_.isPlainObject(result.data)) {
          res(transformToBase64(result.data));
        } else {
          rej(
            "Невірний формат QR-коду. Сповістіть про це адміністратора та спробуйте пізніше."
          );
        }
      });
  });
