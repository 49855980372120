import React, { useEffect, useState } from "react";
import { NavLink, useMatch } from "react-router-dom";

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import _ from "lodash";
import { subscribeCommonData } from "../../firebase/firebase";
import { isInIframe } from "../../helpers";
import { reportUserActivity } from "./Activity";
import { useNavigate } from "react-router-dom";
import { useTeam } from "../../hooks";
import store from "../../store";
import { __DEV__ } from "../../constants";

const auth = getAuth();

// --------------------------------------------------------------
const subscribeCommonDataOnce = _.once(subscribeCommonData);
const subscribeWindowsEvents = _.once(({ navigate }) => {
  window.addEventListener("mousemove", reportUserActivity);
  window.addEventListener("keydown", reportUserActivity);
});

const Auth = () => {
  const { authorised, email, team } = useSelector(({ profile }) => profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authorised) {
      subscribeCommonDataOnce({ dispatch, team, email });
      subscribeWindowsEvents({ navigate });
    }
  }, [authorised]);

  return authorised ? (
    <AuthAuthorized email={email} dispatch={dispatch} />
  ) : (
    <AuthLogin dispatch={dispatch} />
  );
};

const TEAMS_NAMES = {
  osb49: "ОСБ49",
  lyman: "ОТУ Лиман",
};
const shortenedEmail = (email) => {
  if (typeof email !== "string" || email.indexOf("@") === -1) return email;
  const [name, domain] = email.split("@");
  if (domain === "sapiense.com") return name;
  return email;
};

const AuthAuthorized = ({ email, dispatch }) => {
  const signalAutoReceive = useSelector(
    ({ profile }) => profile.signalAutoReceive
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const logoutRequest = async () => {
    try {
      await signOut(auth);
      dispatch({
        type: "USER_LOGOUT_SUCCESS",
        // payload: user
      });
      window.location.reload();
    } catch (err) {
      setError(error.message);
    }
  };
  const team = useTeam();
  const isSettings = useMatch("/settings");

  useEffect(() => {
    return;
    let timeout;
    const createTimeout = () => {
      return setTimeout(
        async () => {
          // __DEV__ && console.log("timeout");
          if (!signalAutoReceive) return (timeout = createTimeout());
          try {
            await __firebase
              .signalApi({ type: "__messagesReceive" })
              .then((res) => {
                if (__DEV__) {
                  console.log("__messagesReceive", res);
                }
                timeout = createTimeout();
              });
          } catch (err) {
            timeout = createTimeout();
          }
        },
        __DEV__ ? 1000 * 2 : 1000 * 60
      );
    };
    timeout = createTimeout();
    return () => {
      clearTimeout(timeout);
    };
  }, [signalAutoReceive]);

  return (
    <>
      <span className="auth__email">
        <NavLink
          to={isSettings ? "/" : "/settings"}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          {shortenedEmail(email)}
        </NavLink>
        , {TEAMS_NAMES[team] || team}{" "}
        <span className="auth__logout" onClick={() => setOpen(true)}>
          вийти
        </span>
      </span>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Вийти з аккаунту " +
            email +
            "? Ви втратите всі невідправленні повідомленя"}
        </DialogTitle>
        {error && <Alert severity="error">{error}</Alert>}

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Відмінити</Button>
          <Button onClick={logoutRequest} autoFocus variant={"outlined"}>
            Вийти
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AuthLogin = () => {
  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const authRequest = async () => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      console.log("SUCCESS AUTH", user);
    } catch (err) {
      const errorCode = err.code;
      setError(err.message);
    }
  };

  return (
    <>
      <Dialog open={opened} onClose={() => setOpened(false)}>
        <DialogTitle>Авторизація</DialogTitle>
        <form action="src/ui/parts/Auth" onSubmit={authRequest}>
          <DialogContent>
            <DialogContentText>
              Для доступу необхідно авторизуватися
            </DialogContentText>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email адреса"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="pass"
              label="Пароль"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpened(false)}>Відмінити</Button>
            <Button onClick={authRequest} variant={"outlined"}>
              Авторизуватись
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <button className="auth__link btn" onClick={() => setOpened(true)}>
        Увійти
      </button>
    </>
  );
};

export default Auth;
