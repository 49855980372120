import CreateMessage from "../CreateMessage/CreateMessage";
import DraftsList from "./DraftsList";
import { useWriteAccess } from "../../hooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { reduceArrayOfStringToForms } from "../../semantic";
import Message from "../parts/Message";
import { isInIframe } from "../../helpers";
import _ from "lodash";
import { __DEV__ } from "../../constants";
import Button from "@mui/material/Button";

const LIMIT_PER_QUERY = __DEV__ ? 5 : isInIframe ? 10 : 30;

const MessagesTab = ({ fqID }) => {
  const fq = parseFloat(fqID);
  const access = useSelector(({ profile }) => profile.access);
  const writeAccess =
    access === "writer" || access === "admin" || access === "operator";

  // todo: optimize me, create selector
  const names = useSelector(({ names }) => {
    return (names.list[fq] || []).reduce(reduceArrayOfStringToForms, []);
  });

  const team = useSelector(({ profile }) => {
    return profile.team;
  });
  const messages = useSelector(({ messages }) => {
    return messages[fq] || [];
  });
  const [loaded, setLoaded] = useState(false);

  const [possibleContinue, setPossibleContinue] = useState(true);

  const constructQuery = (createdAtConstrain) => {
    return [
      collection(db, "messages"),
      where("frequency", "==", fq),
      createdAtConstrain,
      where("team", "==", team),
      orderBy("createdAt", "desc"),
      limit(LIMIT_PER_QUERY),
    ].filter((v) => v != null);
  };

  const requestLoadMore = async () => {
    setLoaded(false);
    const oldestCreatedAt = (_.minBy(messages, "createdAt") || {}).createdAt;
    const res = await getDocs(
      query(...constructQuery(where("createdAt", "<", oldestCreatedAt)))
    );
    setLoaded(true);

    const data = res.docs.map((v) => v.data());
    if (data.length < LIMIT_PER_QUERY) {
      setPossibleContinue(false);
    }
    if (data.length) {
      store.dispatch({
        type: "MESSAGES_SNAPSHOT_PER_FQ",
        payload: {
          data,
          fq,
        },
      });
    }
  };

  useEffect(() => {
    const newestCreatedAt = (_.maxBy(messages, "createdAt") || {}).createdAt;

    const queryArr = constructQuery(
      newestCreatedAt ? where("createdAt", ">", newestCreatedAt) : null
    );
    const unsubscribe = onSnapshot(query(...queryArr), (res) => {
      const data = res.docs.map((v) => v.data());
      // console.log("!!!", {
      //   length: messages.length,
      //   newestCreatedAt,
      //   newLength: data.length,
      // });
      if (data.length) {
        store.dispatch({
          type: "MESSAGES_SNAPSHOT_PER_FQ",
          payload: {
            data,
            fq,
          },
        });
      }
      setLoaded(true);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className={"messages-container"}>
      <DraftsList currentFQ={fq} />
      {writeAccess && <CreateMessage fqID={fqID} />}
      {loaded && messages.length === 0 && (
        <div style={{ marginTop: "20px", color: "gray" }}>
          <i>Перехоплень для данної частоти ще немає</i>
        </div>
      )}
      {messages.length > 0 && (
        <div className="messages-list">
          {messages.map((message) => {
            return (
              <Message key={message.createdAt} data={message} tokens={names} />
            );
          })}
        </div>
      )}

      <div>
        {!loaded && <div style={{ marginTop: "20px", color: "gray" }}>...</div>}
        {loaded && messages.length > 0 && possibleContinue && (
          <Button onClick={requestLoadMore} variant={"outlined"}>
            підгрузити ще
          </Button>
        )}
      </div>
    </div>
  );
};

export default MessagesTab;
