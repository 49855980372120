import { actionHandlers } from "../helpers";

export default actionHandlers(
  {
    MESSAGES_SNAPSHOT: (state, { data }) => {
      data = _.keyBy(data, "frequency");
      return _.mapValues(state, (list, fq) => {
        if (data[fq]) {
          return _.uniqBy([...list, ...data[fq]], (x) => x.createdAt);
        } else {
          return list;
        }
      });
    },
    MESSAGES_SNAPSHOT_PER_FQ: (state, { data, fq }) => {
      const newState = { ...state };

      // rewrite me
      data.forEach((item) => {
        if (newState[fq]) {
          const alreadyExist = newState[fq].some(
            (x) => x.createdAt === item.createdAt
          );
          if (!alreadyExist) {
            newState[fq] = [...newState[fq], item];
          }
        } else {
          newState[fq] = [item];
        }
      });
      if (_.isArray(newState[fq])) {
        newState[fq] = _.orderBy(newState[fq], "createdAt", "desc");
      }

      return newState;
    },
  },
  {}
);
