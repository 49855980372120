import { useScript } from "../../hooks";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";
import React from "react";
import { isInIframe } from "../../helpers";
import formatDate from "date-fns/format";
import Etalonka from "./Etalonka";
import DayTable from "./DayTable";

window.formatDate = formatDate;
const possibleToWorkWithReports = !isInIframe;

export default () => {
  const libStatus = useScript("/lib/sheets.js");
  const lib2Status = useScript("/lib/docx.js");
  return (
    <div className="container">
      {(libStatus === "error" || lib2Status === "error") && (
        <Alert severity="error">
          Виникла помилка при підгрузці бібліотеки, зверніться до адміністратора
        </Alert>
      )}
      {(libStatus === "loading" || lib2Status === "loading") && (
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
      )}
      {libStatus === "ready" && lib2Status === "ready" && (
        <>
          <h1>Звіти</h1>
          <hr />
          <DayTable possibleToWorkWithReports={possibleToWorkWithReports} />
          <hr />
          <Etalonka possibleToWorkWithReports={possibleToWorkWithReports} />
          <hr />
        </>
      )}
    </div>
  );
};
