import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { __DEV__ } from "../../constants";
import Alert from "@mui/material/Alert";
import CallSigns from "./Callsigns";
import Publish from "./Publish";
import DateTime from "./DateTime";
import Tags from "./Tags";
import { Comment, Text } from "./TextAndComment";
import Button from "@mui/material/Button";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { getDefault } from "../../reducers/drafts";
import classNames from "classnames";
import {
  _normalizeNames,
  isInIframe,
  normalizeFq,
  normalizeNameForCross,
  structureMessage,
} from "../../helpers";
import { ErrorBoundary } from "react-error-boundary";
import { fallbackRender } from "./ErrorBoundary";
import Audio from "./Audio";
import { postToPlayer } from "../IframeCommunication";
import {
  generateShortSignalMessage,
  generateExtendedSignalMessage,
} from "./helpers";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import _ from "lodash";
import { useCustomFaction } from "../../hooks";

const emptyMap = {};
const CreateMessage = ({ fqID }) => {
  const whatsapp = useSelector(({ profile }) => profile.whatsapp);
  const hasWhatsappGroup = !_.isEmpty(whatsapp);
  const fq = parseFloat(fqID);
  // const formattedFqId = fqID;
  const formattedFqId = normalizeFq(fqID);
  const draftExists = useSelector(({ drafts }) => drafts[fq] != null);

  const fqmeta = useSelector(({ fqmeta }) => {
    return fqmeta[fq] || emptyMap;
  });

  const linkedSignalGroups =
    useSelector(({ settings }) => settings.linkedSignalGroups) || emptyMap;

  const extendedSignalHeader = useSelector(({ settings }) => {
    return Boolean(settings.extendedSignalHeader);
  });
  const signalGroupsFq = useSelector(({ signalGroupsFq }) => {
    return signalGroupsFq[fq];
  });

  const state = useSelector(({ drafts }) => drafts[fq] || getDefault());
  const draftsExist = useSelector(({ drafts }) => drafts[fq] != null);

  const existingNames = useSelector(({ names }) => names.list[fq] || []);
  const custom_faction = useCustomFaction(fq);
  const dispatch = useDispatch();

  const edit = (update) => {
    dispatch({
      type: "EDIT_DRAFT",
      payload: { fq, update },
    });
  };
  // useEffect(() => {
  //   edit({
  //     selectedGroups: _.mapValues(linkedSignalGroups, () => true),
  //   });
  // }, []);
  // --------------------------------------------------------------
  const {
    text,
    comment,
    callsigns,
    tags,
    audio,
    datetime,
    // --------------------------------------------------------------
    selectedGroups,
  } = state;
  // --------------------------------------------------------------
  const defaultGroups = _.isArray(signalGroupsFq)
    ? signalGroupsFq.reduce((acc, id) => ({ ...acc, [id]: true }), {})
    : _.mapValues(linkedSignalGroups, () => true);

  useEffect(() => {
    if (!draftsExist) return;

    edit({
      selectedGroups: _.isEmpty(selectedGroups)
        ? defaultGroups
        : {
            ...defaultGroups,
            ...selectedGroups,
          },
    });
  }, [draftsExist]);
  // --------------------------------------------------------------
  const [error, setError] = useState(null);
  // --------------------------------------------------------------
  const createdBy = useSelector(({ profile }) => profile.uid);
  const signalAcc = useSelector(({ profile }) => profile.signalAcc);
  const team = useSelector(({ profile }) => profile.team);

  const hasLinkedSignalGroups = _.size(linkedSignalGroups) > 0;
  const chat_title = useSelector(({ names, profile, frequencies }) => {
    return (frequencies[fq] || {}).chat_title;
  });

  // --------------------------------------------------------------
  const filteredCallsigns = callsigns.filter((s) => s.trim() !== "");
  const isFieldsReadyToPublish =
    text.length > 0 || filteredCallsigns.length > 0;
  const isAvailableToPublish = _.some(
    linkedSignalGroups,
    ({ id }) => selectedGroups[id]
  );
  // --------------------------------------------------------------
  const toggleGroup = (id) => {
    return edit({
      ["selectedGroups"]: {
        ...selectedGroups,
        [id]: selectedGroups[id] == null ? true : !selectedGroups[id],
      },
    });
  };
  // --------------------------------------------------------------
  const { locations, factions } = fqmeta;

  const signalMessage = extendedSignalHeader
    ? generateExtendedSignalMessage({
        text,
        datetime,
        filteredCallsigns,
        fqID: formattedFqId,
        comment,
        locations,
        factions,
        custom_faction,
      })
    : generateShortSignalMessage({
        text,
        datetime,
        filteredCallsigns,
        fqID: formattedFqId,
        chat_title,
        comment,
      });

  // --------------------------------------------------------------
  const publishRequest = async () => {
    const signalRecipients = _.keys(_.pickBy(selectedGroups, (v) => v));
    //   .filter(
    //   (id) => typeof linkedSignalGroups[id] !== "undefined"
    // );
    // if (__DEV__) {
    //   return;
    // }
    dispatch({
      type: "SAVE_LINKEDGROUPS_FOR_FQ",
      payload: {
        fq,
        signalRecipients,
      },
    });
    const message = {
      text,
      comment,
      datetime,
      frequency: fq,
      callsigns: filteredCallsigns,
      createdAt: new Date().valueOf(),
      createdBy,
      team,
      locations: (fqmeta.locations || []).map(({ id }) => id),
      factions: (fqmeta.factions || []).map(({ uuid }) => uuid),
    };

    if (audio.length) {
      message.audio = audio;
    }
    if (signalAcc && signalRecipients.length > 0) {
      message.__signal = {
        recipients: signalRecipients,
        message: signalMessage,
        signalAcc,
      };
    }
    // console.log(message.__signal);
    // return;

    console.log("[CreateMessage] send to server", message);

    try {
      const docRef = await addDoc(collection(db, "messages"), message);
      console.log("[CreateMessage] saved to server", message, docRef);
      edit(null);

      const normalizedNames = existingNames.map(normalizeNameForCross);
      const notExistedInNames = filteredCallsigns
        .filter(
          (str) => normalizedNames.indexOf(normalizeNameForCross(str)) === -1
        )
        .map((s) => s.trim());

      if (notExistedInNames.length) {
        setDoc(
          doc(db, "names", team),
          {
            [fq]: _normalizeNames(
              [...existingNames, ...notExistedInNames].join(",")
            ).join(", "),
          },
          { merge: true }
        );
      }
    } catch (err) {
      setError(err.toString());
      console.error(err);
    }
  };
  // --------------------------------------------------------------
  useEffect(() => {
    if (__DEV__ && !isInIframe) {
      // edit({
      //   text: "Вася, там у тебя из окопа идет дым \n " + new Date().valueOf(),
      //   callsigns: ["Кама", "Амур"],
      //   comment: "Задумали шось лихе",
      // });
    }
  }, []);
  useEffect(() => {
    if (isInIframe && audio.length === 0) {
      postToPlayer({ type: "CLEAN_SELECTED", payload: { fq } });
    }
  }, [audio]);
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error]);

  const setDatetime = useCallback((v) => edit({ datetime: v }));
  const setComment = useCallback((v) => edit({ comment: v }));
  const setText = useCallback((v) => edit({ text: v }));
  const setCallsigns = useCallback((v) => edit({ callsigns: v }));
  const setTags = useCallback((v) => edit({ tags: v }));
  const removeDraft = useCallback((v) => edit(null));

  return (
    <div className={"create-message"}>
      <div
        className={classNames("create-message__content", {
          // "create-message__content_withBottom": isBottomShowed,
        })}
      >
        {/*<div className="create-message__clean">
          <IconButton>
            <HighlightOffIcon></HighlightOffIcon>
          </IconButton>
        </div>*/}
        {error && (
          <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
        )}

        <div className="row">
          <div className="col-sm">
            <Text text={text} setText={setText} />
            <Comment comment={comment} setComment={setComment} />
            <Audio audio={audio} />
          </div>
          {((signalAcc && hasLinkedSignalGroups) ||
            (whatsapp && hasWhatsappGroup)) && (
            <div className="col-sm">
              <SignalPreview message={signalMessage} />

              <Publish
                whatsapp={whatsapp}
                groups={linkedSignalGroups}
                selectedGroups={selectedGroups}
                toggleGroup={toggleGroup}
                defaultGroups={defaultGroups}
                draftExists={draftExists}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <CallSigns
              callsigns={callsigns}
              setCallsigns={setCallsigns}
              fq={fq}
            />
          </div>
          <div className="col">
            <DateTime datetime={datetime} setDatetime={setDatetime} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/*<hr />*/}
            <Tags tags={tags} setTags={setTags} />
          </div>
        </div>
      </div>
      <div className="create-message__bottom">
        <RemoveButton removeDraft={removeDraft} />
        <Button
          variant="contained"
          onClick={publishRequest}
          disabled={!isFieldsReadyToPublish}
        >
          {"Створити перехоплення" +
            (isAvailableToPublish ? " та відправити в signal" : "")}
        </Button>
      </div>
    </div>
  );
};
const RemoveButton = ({ removeDraft }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="text"
        size="small"
        onClick={() => setOpen(true)}
        tabIndex={-1}
      >
        Видалити чорновик
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Видалити чорновик перехоплення?
        </DialogTitle>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Відмінити</Button>
          <Button
            onClick={() => {
              setOpen(false);
              removeDraft();
            }}
            autoFocus
            variant={"contained"}
          >
            Видалити
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SignalPreview = ({ message }) => {
  const structured = structureMessage(message);
  return (
    <div className="create-message__signal-preview">
      <div className={"create-message__preview"}>
        <div>{structured}</div>
      </div>
    </div>
  );
};

const WrapperCreateMessage = React.memo((props) => {
  const dispatch = useDispatch();
  const fq = parseFloat(props.fqID);

  const draftExists = useSelector(({ drafts }) => drafts[fq] != null);
  const [opened, setOpened] = useState(draftExists || isInIframe);
  // const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (!isInIframe && !opened && draftExists) {
      setOpened(true);
    }
  }, [draftExists, opened]);

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={({ args }) => {
        if (args[0] === true) {
          dispatch({
            type: "CLEAN_DRAFTS",
          });
        } else if (args[0] === false) {
          dispatch({
            type: "EDIT_DRAFT",
            payload: {
              fq,
              update: null,
            },
          });
        }
      }}
    >
      {opened && <CreateMessage {...props} key={fq} />}
      {!opened && (
        <Button onClick={() => setOpened(true)} variant={"contained"}>
          Додати нове перехоплення
        </Button>
      )}
    </ErrorBoundary>
  );
}, shallowEqual);

export default WrapperCreateMessage;
