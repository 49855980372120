import React from "react";

const Name = React.memo(
  ({
    isMatchSearchValue,
    isActiveName,
    n,
    maxValue,
    nameToFs,
    isLast,
    onClick,
  }) => {
    // console.log('name')
    // const getBGColor = () => {
    //     // if (n[0].toLowerCase() === n[0]) return 'aqua'
    //     if (n.indexOf(' ') !== -1) return '#acd5ff'
    //     if (n.indexOf('-') !== -1) return '#acffcb'
    //     // if (n.toLowerCase().search(/ҐЄІЇ/)) return '#acffcb'
    // }
    let style = {
      fontSize: 60 + (70 / maxValue) * (nameToFs || "").length + "%",
    };
    if (isMatchSearchValue) {
      style.backgroundColor = "#c86000";
    }
    if (isActiveName) {
      style.textDecoration = "underline";
    }

    return (
      <>
        <span style={style} onClick={onClick(n)}>
          {n}
        </span>
        {!isLast ? ", " : ""}
      </>
    );
  },
  (prev, next) => {
    return (
      prev.isMatchSearchValue === next.isMatchSearchValue &&
      prev.isActiveName === next.isActiveName
    );
  }
);
export default Name;
