import _ from "lodash";
import formatDate from "date-fns/format";
import copy from "copy-to-clipboard";
import React from "react";
import { shallowEqual } from "react-redux";
import proj4 from "proj4";

export const uniqAndOrder = (names) => {
  if (names.length <= 1) return names;
  return _.orderBy(_.uniq(names), [(n) => (n[0] || "").toLowerCase()], ["asc"]);
};
// --------------------------------------------------------------
export const onCopyClick = ({ fq, chat_title, date = new Date() }) => {
  const arr = [];
  arr.push(normalizeFq(fq));
  if (chat_title) {
    arr.push(chat_title);
  }
  arr.push(formatDate(date, "HH:"));

  copy(arr.join("/"));
};
// --------------------------------------------------------------
export const structureMessage = (str) => {
  return str.split("\n").reduce((acc, v) => {
    acc.push(v);
    acc.push(<br key={Math.random()} />);
    return acc;
  }, []);
};
// --------------------------------------------------------------
export const formatMessage = (str) => {
  return str;
  // const splitted = str.split("\n");
  // if (splitted.length === 1) return str;
  // return splitted
  //   .map((s) => (s === "" ? "" : s.trim()[0] === "-" ? s : "- " + s))
  //   .join("\n");
};

// export const downloadFile = ({ blob, name }) => {
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement("a");
//   a.style.display = "none";
//   a.href = url;
//   a.download = name;
//   document.body.appendChild(a);
//   a.click();
//   window.URL.revokeObjectURL(url);
// };
// --------------------------------------------------------------
export const capFirst = (s) =>
  s.length === 0 ? s : s[0].toUpperCase() + s.slice(1).toLowerCase();
// --------------------------------------------------------------
// const TOKENS = ["200", "300", ""];
// export const highlightMessage = (list) => {
//   return list.map((str) => {
//     if (typeof str !== "string") return str;
//
//     // innerHTML = innerHTML.substring(0,index) + "<span class='highlight'>"
//     //   + innerHTML.substring(index,index+text.length)
//     //   + "</span>" + innerHTML.substring(index + text.length);
//   });
// };
// --------------------------------------------------------------
export const formatText = (text) => {
  if (typeof text !== "string") return text;
  if (text.trim() === "") return "[відсутня інформація]";

  const splittedByBreaks = text.split("\n");
  return splittedByBreaks.reduce((acc, str, i) => {
    if (str.trim() === "") return acc;
    if (i !== 0) acc.push(<br key={i} />);
    acc.push(str);
    return acc;
  }, []);
};
// --------------------------------------------------------------
const fqCache = {};
export const normalizeFq = (fq) => {
  if (typeof fq !== "number") {
    try {
      fq = parseFloat(fq);
      if (_.isNaN(fq) || fq <= 0) throw 1;
    } catch (err) {
      return "[not recognized]";
    }
  }
  if (fqCache[fq]) return fqCache[fq];

  const str = String(fq);
  if (str.indexOf(".") !== -1 && str.split(".")[1].length > 3) {
    return (fqCache[fq] = str);
  }

  return (fqCache[fq] = parseFloat(fq).toFixed(3));
};

// --------------------------------------------------------------
export const formatCK42 = (s) => s.slice(0, 2) + " " + s.slice(2);
// --------------------------------------------------------------

export const fullscreenEffect = () => {
  function isFullscreen(element) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }
  // rewrite this function to use requestFullscreen

  function requestFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
  const onkeydown = (ev) => {
    const elementToSendFullscreen = document.body;

    if (ev.code === "KeyF" && ["f", "F", "а", "А"].some((k) => ev.key === k)) {
      if (isFullscreen(elementToSendFullscreen)) {
        exitFullscreen();
      } else {
        requestFullscreen(elementToSendFullscreen);
      }
    }
    // if (ev.code === 'KeyG') {
    //
    // }
  };
  document.addEventListener("keydown", onkeydown);
  return () => {
    document.removeEventListener("keydown", onkeydown);
  };
};
// --------------------------------------------------------------
export const normalizeFqToNum = (fq) => parseFloat(fq).toString();

export const _normalizeNames = (names) => {
  return uniqAndOrder(
    names
      .replace("\n", " ")
      .split(/[,]+/)
      .map((n) => {
        n = n.trim();
        // n = n.replace('')
        return n;
      })
      .filter((n) => n !== "")
  );
};
// --------------------------------------------------------------
export const isEqualArrayOfStrings = (x, y) => {
  if (x.length !== y.length) return false;
  for (var i = 0; i < x.length; i++) {
    if (x[i] !== y[i]) return false;
  }
  return true;
};
// --------------------------------------------------------------
export const actionHandlers = (rules, initial) => (state, action) => {
  if (rules[action.type]) return rules[action.type](state, action.payload);
  return state || initial;
};
// --------------------------------------------------------------
const parseNameWithRoleCache = {};
export const parseNameWithRole = (n) => {
  if (parseNameWithRoleCache[n]) return parseNameWithRoleCache[n];
  const pos = n.search(/\([^()]*\)/g);
  if (pos === -1) return (parseNameWithRoleCache[n] = [n, ""]);
  // todo: capture role without brackets
  return (parseNameWithRoleCache[n] = [
    n.slice(0, pos).trimRight(),
    n.slice(pos),
  ]);
};
// --------------------------------------------------------------
const normalizeNameForCrossCache = {};
export const normalizeNameForCross = (n) => {
  if (normalizeNameForCrossCache[n]) return normalizeNameForCrossCache[n];
  const filtered = parseNameWithRole(n)[0];
  if (filtered.length === 0) return (normalizeNameForCrossCache[n] = filtered);
  const lowerCase = filtered.toLowerCase().replace("ё", "е");
  return (normalizeNameForCrossCache[n] =
    lowerCase[0].toUpperCase() + lowerCase.slice(1));
};

// --------------------------------------------------------------
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
export const isInIframe = inIframe();
// --------------------------------------------------------------
export const groupChangesFromCollectionSnapshot = (input, indexKey) => {
  const changes = input.docChanges();

  const toUpdate = changes
    .filter(({ type }) => type === "added" || type === "modified")
    .reduce((acc, { doc }) => {
      const data = {
        _id: doc.id,
        ...doc.data(),
      };
      return { ...acc, [indexKey ? data[indexKey] : doc.id]: data };
    }, {});

  const toRemove = changes
    .filter(({ type }) => type === "removed")
    .reduce((acc, { doc }) => {
      const data = doc.data();
      return { ...acc, [indexKey ? data[indexKey] : doc.id]: data };
    }, {});
  return {
    toUpdate,
    toRemove,
  };
};
// --------------------------------------------------------------
// todo: unneccessary checks, move value->snapshot
export const updateCollection = ({ name, match }) => {
  return (state, { collection, docs }) => {
    if (collection !== name) return state;
    let isUpdated = false;

    // remove
    const oldLength = state.length;
    state = state.filter((item) => docs.some((doc) => match(item, doc)));
    if (oldLength !== state.length) isUpdated = true;

    // update
    let newState = state.map((s) => {
      const updateIndex = _.findIndex(docs, (d) => match(s, d));
      if (updateIndex !== -1) {
        const newItem = docs[updateIndex];
        docs = _.filter(docs, (v, i) => i !== updateIndex);
        if (shallowEqual(s, newItem)) return s;
        isUpdated = true;
        return newItem;
      }
      return s;
    });

    // add
    if (docs.length > 0) {
      isUpdated = true;
      newState = state.concat(docs);
    }

    return isUpdated ? newState : state;
  };
};

proj4.defs(
  "EPSG:28406",
  "+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,-0.35,-0.79,-0.22 +units=m +no_defs +type=crs"
);
proj4.defs(
  "EPSG:28407",
  "+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,-0.35,-0.79,-0.22 +units=m +no_defs +type=crs"
);
proj4.defs(
  "EPSG:28405",
  "+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0,-0.35,-0.79,-0.22 +units=m +no_defs +type=crs"
);
export function sk42ToWgs84(coordinates) {
  const { x, y, zone } = coordinates;

  if (x && y && zone) {
    const result = proj4(`EPSG:2840${zone}`).inverse({
      x: _.toNumber(y),
      y: _.toNumber(x),
    });

    result.x = _.toNumber(result.x);
    result.y = _.toNumber(result.y);

    return { lat: result.y, lng: result.x };
  }

  throw new Error(
    "coordinates missing one of: x, y, zone values in sk42ToWgs84 func"
  );
}

export const prepareFactionsForSearch = (factions, addIndex = true) => {
  return _.toArray(factions).map((v) => {
    const parents = v.parent_path.map((id) => factions[id]);
    const obj = {
      ...v,
      label: v.name,
      parents,
    };
    if (addIndex) {
      obj._search = [v.name, v.short_name, ...parents.map((v) => v.name)].join(
        " "
      );
    }
    return obj;
  });
};
