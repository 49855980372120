import _ from "lodash";
import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useTeam, useWriteAccess } from "../../hooks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import LocationRow from "../parts/LocationRow";
import FuzzySearch from "fuzzy-search";
import { prepareFactionsForSearch } from "../../helpers";
import FgField from "../parts/FgField";
import { useCustomFaction } from "../../hooks";
let lastSearchTxt;
const FqFactions = ({ fq, frequency }) => {
  const team = useTeam();
  const [searchTxt, setSearchTxt] = useState("");
  const [searcher, setSearcher] = useState(null);
  const [error, setError] = useState(null);
  const fqmeta = useSelector(({ fqmeta }) => fqmeta[fq]);
  const term = searchTxt.toLowerCase().trim();
  const options = searchTxt.length > 0 && searcher ? searcher.search(term) : [];
  const writeAccess = useWriteAccess();
  const custom_faction = useCustomFaction(fq);
  const extendedSignalHeader = useSelector(({ settings }) => {
    return Boolean(settings.extendedSignalHeader);
  });

  const factions = useSelector(({ factions }) => factions);
  const factionsExist =
    _.isPlainObject(fqmeta) &&
    _.isArray(fqmeta.factions) &&
    fqmeta.factions.length > 0;

  const loading = _.isEmpty(factions);

  const pickForSave = (obj) =>
    _.pick(obj, ["id", "uuid", "name", "short_name", "parents"]);

  const requestAddFaction = async (v) => {
    v = {
      ...pickForSave(v),
      parents: v.parents.map(pickForSave),
    };
    console.log("requestAddFaction", v);

    try {
      let res;
      const common = {
        team,
        fq,
        updatedAt: new Date().valueOf(),
      };
      if (_.isPlainObject(fqmeta)) {
        const payload = {
          ...common,
          factions: fqmeta.factions ? [...fqmeta.factions, v] : [v],
        };
        res = setDoc(doc(db, "fqmeta", fqmeta._id), payload, { merge: true });
      } else {
        res = addDoc(collection(db, "fqmeta"), {
          ...common,
          factions: [v],
        });
      }
      console.log("success add/edit factions", res);
    } catch (err) {
      setError(err.toString());
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (searchTxt.length === 0) return;
    if (!_.isEmpty(factions)) {
      if (searcher === null) {
        setSearcher(
          new FuzzySearch(
            prepareFactionsForSearch(factions),
            ["name", "_search"],
            {
              sort: true,
            }
          )
        );
      }
      return;
    }

    getDoc(doc(db, "dictionaries", "factionsOptions")).then((docSnap) => {
      const data = docSnap.data();
      const factions = _.keyBy(data.list, "id");
      setSearcher(
        new FuzzySearch(prepareFactionsForSearch(factions), ["_search"], {
          sort: true,
        })
      );
      dispatch({
        type: "SET_FACTIONS",
        payload: factions,
      });
    });
  }, [searchTxt]);

  return (
    <>
      <div className={"fq__factions container-fluid"}>
        {error && (
          <div className="row">
            <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
          </div>
        )}
        <div className="row fq__factions-row">
          <div className="fq__factions-fields">
            {factionsExist && (
              <div className="fq__factions-list">
                {fqmeta.factions.map((data) => {
                  return (
                    <Faction
                      key={data.uuid}
                      data={data}
                      fqmeta={fqmeta}
                      team={team}
                      fq={fq}
                      setError={setError}
                      writeAccess={writeAccess}
                    />
                  );
                })}
              </div>
            )}
            {writeAccess && (
              <Autocomplete
                open={term.length >= 2}
                className={"fq__factions-autocomplete"}
                getOptionDisabled={
                  factionsExist
                    ? ({ id }) => {
                        return fqmeta.factions.some((v) => v.id === id);
                      }
                    : null
                }
                disablePortal
                options={options}
                loading={loading}
                loadingText={"Завантажуємо..."}
                filterOptions={(x) => x}
                // clearIcon={null}
                noOptionsText={"Нічого не знайдено"}
                onChange={(event, newValue) => {
                  if (_.isPlainObject(newValue) && newValue.label) {
                    setSearchTxt("");
                    requestAddFaction(newValue);
                  }
                }}
                onInputChange={({ target }) => {
                  if (typeof target.value === "string") {
                    setSearchTxt(target.value);
                  } else {
                    setSearchTxt("");
                  }
                }}
                renderOption={(props, obj) => {
                  return (
                    <div
                      {...props}
                      className={props.className + ""}
                      key={obj.id}
                    >
                      <div className="fq__faction-option">
                        <div>
                          <b>{obj.short_name}</b> {obj.name}
                        </div>
                        <div className={"fq__faction-option_secondrow"}>
                          {obj.parents.map((v, i) => {
                            if (!_.isPlainObject(v)) return v;
                            return (
                              <span title={v.name} key={v.uuid}>
                                {i !== 0 ? " ♦ " : ""}
                                {v.name}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={`Додайте конкретний підрозділ ворога`}
                      variant="outlined"
                      value={searchTxt}
                      size={"small"}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...params.inputProps.style,
                          // minWidth: value.length * 7,
                        },
                        value: searchTxt,
                      }}
                    />
                  );
                }}
              />
            )}
          </div>
        </div>
      </div>
      {!factionsExist && extendedSignalHeader && (
        <div className="row">
          <div className="col">
            <FgField
              fq={fq}
              title={"Нечітка назва підрозділу (Арта, Танчіки)"}
              name={"custom_faction"}
              note={"Використовується для формування signal-повідомлення"}
              placeholder={custom_faction}
            />
          </div>
        </div>
      )}
    </>
  );
};

const Faction = ({ data, fqmeta, team, fq, setError, writeAccess }) => {
  const requestRemoveLocation = () => {
    try {
      let res;
      const common = {
        team,
        fq,
        updatedAt: new Date().valueOf(),
      };
      res = setDoc(
        doc(db, "fqmeta", fqmeta._id),
        {
          ...common,
          factions: fqmeta.factions.filter(({ uuid }) => uuid !== data.uuid),
        },
        { merge: true }
      );
      console.log("success remove faction", res);
    } catch (err) {
      setError(err.toString());
    }
  };
  return (
    <div className="fq__loc-item">
      <div className={"fq__faction"}>
        <div className={"fq__faction-name"}>
          <b>{data.short_name}</b> {data.name}
        </div>
        <div className={"fq__faction-parents"}>
          {data.parents.map((v, i) => {
            if (!_.isPlainObject(v)) return v;
            return (
              <span title={v.name} key={v.uuid}>
                {i !== 0 ? " ♦ " : ""}
                {v.name}
              </span>
            );
          })}
        </div>
      </div>
      {writeAccess && (
        <div className="fq__loc-item-close" onClick={requestRemoveLocation}>
          <IconButton aria-label="delete" size="small">
            <HighlightOffIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default FqFactions;
