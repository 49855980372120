import { actionHandlers } from "../helpers";

export default actionHandlers(
  {
    ["SETTINGS_SNAPSHOT"]: (state, payload) => {
      return payload;
    },
  },
  {}
);
