import React, { useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { isInIframe } from "../../helpers";

export const Comment = React.memo(
  ({ comment, setComment }) => {
    const fieldRef = useRef();
    const save = () => {};
    return (
      <div className="create-message__comment">
        <TextareaAutosize
          placeholder={"Комментарі, координати абонентів, тощо"}
          ref={fieldRef}
          className="form-control fq-field__textarea"
          id={`create-message__comment`}
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          onBlur={({ target }) => {
            if (target.value !== comment) {
              setComment(target.value, save);
            }
          }}
        />
      </div>
    );
  },
  (prev, next) => prev.comment === next.comment
);

export const Text = React.memo(
  ({ text, setText }) => {
    const fieldRef = useRef();

    useEffect(() => {
      // fieldRef.current && fieldRef.current.focus();
    }, []);

    const save = () => {};
    return (
      <div className="create-message__text">
        <TextareaAutosize
          minRows={5}
          placeholder={"Текст перехоплення"}
          ref={fieldRef}
          className="form-control create-message__textarea"
          id={`create-message__text`}
          value={text}
          onChange={({ target }) => setText(target.value)}
          onBlur={({ target }) => {
            if (target.value !== text) {
              setText(target.value, save);
            }
          }}
        />
      </div>
    );
  },
  (x, y) => {
    return x.text === y.text;
  }
);
