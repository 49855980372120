import { formatText } from "../../helpers";
import { useTeam, useWriteAccess } from "../../hooks";
import { shallowEqual, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import TextareaAutosize from "react-textarea-autosize";

const FgField = ({
  fq,
  title,
  name,
  children,
  disabled,
  note,
  placeholder,
}) => {
  const frequency = useSelector(({ names, frequencies, fqmeta, drafts }) => {
    return frequencies[fq];
  });

  const writeAccess = useWriteAccess();
  const team = useTeam();
  const stored = useSelector(
    ({ frequencies }) => (frequencies[fq] || {})[name]
  );

  const [value, setValue] = useState(stored || "");
  const fieldRef = useRef();

  useEffect(() => {
    setValue(stored);
  }, [stored]);

  const save = (v) => {
    if (!writeAccess) return;
    if (typeof v !== "string") return;
    const data = {
      ...frequency,
      [name]: v,
    };

    setDoc(doc(db, "frequencies", team), { [fq]: data }, { merge: true });
  };

  const childrenComponent =
    typeof children === "function" ? children({ value }) : null;

  return (
    <div className="form-group fq-field">
      <div>
        <label htmlFor={`FqField[${name}]`}>
          <b>{title}</b>
        </label>
      </div>
      {writeAccess && (
        <TextareaAutosize
          disabled={disabled}
          placeholder={placeholder || "..."}
          ref={fieldRef}
          className="form-control fq-field__textarea"
          id={`FqField[${name}]`}
          value={value}
          onChange={({ target }) => setValue(target.value)}
          // onKeyDown={(ev) => {
          // todo: only for input
          //   // Enter should blur
          //   if (window.event.keyCode === 13) {
          //     ev.preventDefault();
          //     fieldRef.current.blur();
          //   }
          // }}
          onBlur={({ target }) => {
            if (target.value === stored) return;
            save(target.value);
          }}
        />
      )}
      {!writeAccess && (
        <div className="fq-field__text">
          {value ? formatText(value) : <InfoAbsent />}
        </div>
      )}
      {childrenComponent && (
        <div className="fq-field__children">{childrenComponent}</div>
      )}
      {note && (
        <div className="fq-field__children">
          <i className="fq-field__note">{note}</i>
        </div>
      )}
    </div>
  );
};
export const InfoAbsent = () => <i>Інформація відсутня</i>;

export default FgField;
