import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { fetchQRCode, fetchSignalGroups } from "./helpers";
import SignalGroups from "./SignalGroups";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";

const SignalConnection = ({ saveSettings }) => {
  const profile = useSelector(({ profile }) => profile);
  const [error, setError] = useState(null);
  const [qrcode, setQrcode] = useState(null);
  // --------------------------------------------------------------
  const [initialNumberCheck, setInitialNumberCheck] = useState(null);
  const [checkConnectionStatus, setCheckConnectionStatus] = useState(false);

  useEffect(() => {
    let checkConnectionInterval;
    const f = async () => {
      try {
        // перший перевірочний
        await fetchSignalGroups();
        setInitialNumberCheck(true);
      } catch (err) {
        setInitialNumberCheck(false);

        try {
          const qrcodestr = await fetchQRCode();
          setQrcode(qrcodestr);

          // очікування підключення
          checkConnectionInterval = setInterval(async () => {
            setCheckConnectionStatus(true);

            try {
              await fetchSignalGroups();
              setCheckConnectionStatus(false);
              clearInterval(checkConnectionInterval);
              setInitialNumberCheck(true); // run signal groups
            } catch (err) {
              setCheckConnectionStatus(false);
            }
          }, 5000);
        } catch (err) {
          setError(err.toString());
        }
      }
    };
    f();

    return () => clearInterval(checkConnectionInterval);
  }, []);

  return (
    <div style={{ marginTop: 40, marginBottom: 40 }}>
      <h3>Підключення Signal</h3>

      {error && (
        <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
      )}

      <div style={{ margin: "10px 0" }}>
        <b>Перевірка підключення:</b>{" "}
        {initialNumberCheck === null && (
          <Skeleton
            variant={"text"}
            sx={{
              display: "inline-block",
              width: "100px",
              marginLeft: "10px",
            }}
          />
        )}
        {initialNumberCheck === true && "підключено"}
        {initialNumberCheck === false &&
          `до аккаунту ${profile.signalAcc} не знайденно активної сессії. Ви можете підключити аккаунт, використовуючи QR-код нижче`}
      </div>

      {initialNumberCheck === false && (
        <div style={{ margin: "20px 0" }}>
          {qrcode === null && (
            <Skeleton variant="rounded" width={200} height={200} />
          )}
          {typeof qrcode === "string" && (
            <div>
              <Alert severity="info">
                Будьте уважні, підключіть саме {profile.signalAcc}. Інші номера
                не будуть підключенні до программи. Якщо у вас є потреба звязати
                інший номер телефону, зверніться до адміністратора.
              </Alert>
              <br />
              <div style={{ textAlign: "center" }}>
                <img
                  src={qrcode}
                  alt="QR Code для підключення signal"
                  style={{ minHeight: "400px" }}
                />
                {checkConnectionStatus && <div>перевіряємо...</div>}
              </div>
            </div>
          )}
        </div>
      )}

      {initialNumberCheck === true && (
        <SignalGroups saveSettings={saveSettings} setError={setError} />
      )}
    </div>
  );
};

// --------------------------------------------------------------

export default SignalConnection;
