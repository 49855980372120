import { capFirst, formatMessage } from "../../helpers";
import formatDate from "date-fns/format";

export const generateShortSignalMessage = ({
  text,
  datetime,
  filteredCallsigns,
  fqID,
  chat_title,
  comment,
}) => {
  const formattedDate = formatDate(datetime, "HH:mm");
  const abonents = filteredCallsigns.join(", ").toUpperCase();

  const str = `
${fqID}/${chat_title ? chat_title.trim() + "/" : ""}${formattedDate}\n
${text.trim() + "\n"}${comment ? "\n(" + comment + ")" : ""}${
    filteredCallsigns.length ? "\n" + abonents : ""
  }
    `;
  return str.trim();
};

/*
+ дата і час
+ частота
+ ідентифікація противника
(підр-л зі складу 252 мсп)
Або ( н/в арт.підр-л)
+ район
- Хто - Кому
- і далі пішов перехват.
* */

export const generateExtendedSignalMessage = ({
  text,
  datetime,
  filteredCallsigns,
  fqID,
  comment,
  locations,
  factions,
  custom_faction,
}) => {
  const formattedDate = formatDate(datetime, "dd.MM, HH:mm");
  const abonents = filteredCallsigns.length
    ? filteredCallsigns.join(", ").toUpperCase()
    : "";

  const locationString =
    locations && locations.length > 0
      ? locations.map(({ name_ua }) => capFirst(name_ua)).join(", ")
      : "НВ";

  const factionsString =
    factions && factions.length > 0
      ? factions.map(({ short_name }) => short_name).join(", ")
      : custom_faction || "НВ";

  const str = `
${fqID} | ${formattedDate}
${factionsString}
${locationString}${abonents ? "\n" + abonents : ""}

${text.trim()}
${comment ? "\n(" + comment + ")" : ""}
  `;
  return str.trim();
};
