import { actionHandlers } from "../helpers";

export default actionHandlers(
  {
    ["SAVE_LINKEDGROUPS_FOR_FQ"]: (state, { fq, signalRecipients }) => {
      return {
        ...state,
        [fq]: signalRecipients,
      };
    },
  },
  {}
);
