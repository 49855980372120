import React, {useState} from "react";
import { getAuth, updatePassword } from "firebase/auth";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

const PasswordChange = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [opened, setOpened] = useState(false);
  const [message, setMessage] = useState(null);

  const handleOpen = (open) => {
    setOpened(open);
    setMessage(null);
  }

  const changePassword = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage({
        message: "Введений пароль не співпадає з перевірочним",
        severity: "error",
      })
      return;
    }

    const { currentUser } = getAuth();
    if (currentUser) {
      updatePassword(currentUser, newPassword).then(() => {
        setMessage({
          message: "Пароль змінено!",
          severity: "success",
        })
      }).catch((e) => {
        setMessage({
          message: e.code === "auth/requires-recent-login"
            ? "Пройшло занадто багато часу між логіном та спробою зміни паролю. " +
            "Будь ласка, виконайте логін ще раз і спробуйте знову."
            : "При зміні паролю сталась помилка",
          severity: "error",
        })
      })

      // updatePassword(currentUser, newPassword);
    }
  }

  return (
    <Accordion sx={{ mt: 2, maxWidth: 400 }} expanded={opened}  onChange={() => {
      handleOpen(!opened)
    }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
      >
        Зміна паролю
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <form onSubmit={changePassword}>
          <Stack direction="column" spacing={1}>
            <TextField
              autoFocus
              name="password"
              margin="dense"
              label="Новий пароль"
              type="password"
              fullWidth
              required
              variant="standard"
              value={newPassword}
              onChange={({target}) => setNewPassword(target.value)}
            />
            <TextField
              name="passwordConfirm"
              margin="dense"
              label="Пароль ще раз"
              type="password"
              fullWidth
              required
              variant="standard"
              value={confirmPassword}
              onChange={({target}) => setConfirmPassword(target.value)}
            />
            {message && <Alert severity={message.severity}>{message.message}</Alert>}
            <Stack direction="row" justifyContent="space-between">
              <Button type="submit" variant={"outlined"} color="warning">
                Змінити пароль
              </Button>
              <Button type="reset" variant={"outlined"} onClick={() => handleOpen(false)}>
                Закрити
              </Button>
            </Stack>
          </Stack>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}
export default PasswordChange;
