import {
  _normalizeNames,
  normalizeFq,
  normalizeNameForCross,
} from "../../helpers";
import { useTeam, useWriteAccess } from "../../hooks";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { InfoAbsent } from "../parts/FgField";
import TextareaAutosize from "react-textarea-autosize";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import _ from "lodash";
import { __DEV__ } from "../../constants";

const AddName = React.memo(
  ({ names, fq, team }) => {
    const ref = useRef();
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);
    const normalizedValue = value.trim();
    const options = names.map((n) => ({ label: n }));
    const alreadyExist = names.includes(normalizedValue);
    const couldAdd = normalizedValue.length > 0 && !alreadyExist;

    useEffect(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, []);

    const requestAdd = async () => {
      if (!couldAdd) return;

      setError(null);
      setValue("");

      if (ref && ref.current) {
        ref.current.focus();
      }

      setTimeout(() => {
        try {
          const res = setDoc(
            doc(db, "names", team),
            {
              [fq]: _normalizeNames([...names, normalizedValue].join(",")).join(
                ", "
              ),
            },
            { merge: true }
          );
        } catch (err) {
          setError(err.toString());
        }
      }, 200);
    };

    return (
      <div className="fq-add-name">
        {error && (
          <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
        )}
        <div className="fq-add-name__row">
          <Autocomplete
            className={"fq-add-name__autocomplete"}
            disablePortal
            options={options}
            freeSolo
            clearIcon={null}
            onChange={(event, newValue) => {
              if (newValue && newValue.label) {
                setValue(newValue.label);
              } else if (newValue == null) {
                setValue("");
              }
            }}
            onInputChange={({ target }) => {
              if (typeof target.value === "string") {
                setValue(target.value);
              } else if (target.value === 0) {
                setValue("");
              }
            }}
            // onBlur={requestAdd}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                requestAdd();
                // ref && ref.current && ref.current.blur();
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputRef={ref}
                  label={`Додати позивний`}
                  variant="outlined"
                  value={value}
                  inputProps={{
                    ...params.inputProps,
                    value: value,
                    style: {
                      ...params.inputProps.style,
                      // minWidth: value.length * 7,
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
          />
          <Button
            disabled={!couldAdd}
            variant={"contained"}
            onClick={requestAdd}
          >
            Додати
          </Button>
        </div>
      </div>
    );
  },
  (prev, next) => {
    return prev.names.length === next.names.length && prev.fq === next.fq;
  }
);

const NamesTab = ({ fqID }) => {
  const fq = parseFloat(fqID);
  const writeAccess = useWriteAccess();
  // const writeAccess = false;

  const namesFieldRef = useRef(null);
  const team = useTeam();

  const { cross, names } = useSelector(({ names, profile, frequencies }) => {
    // TODO: make createSelector, heavy recalculation
    const fq = parseFloat(fqID);
    const cross = _.orderBy(
      names.sortedFqList.reduce((acc, fq1) => {
        fq1 = parseFloat(fq1);
        if (fq1 === fq) return acc;
        const cross = _.intersectionBy(
          names.list[fq],
          names.list[fq1],
          normalizeNameForCross
        );
        if (cross.length === 0) return acc;
        acc.push([fq1, fq, cross]);
        return acc;
      }, []),
      (a) => a[2].length,
      ["desc"]
    );

    return {
      cross,
      names: names.list[fq] || [],
    };
  }, shallowEqual);

  const normalizedFqID = normalizeFq(fqID);

  const list = cross
    .map(([fq1, fq2, cross]) => [normalizeFq(fq1), normalizeFq(fq2), cross])
    .filter(
      ([fq1, fq2, cross]) => fq1 === normalizedFqID || fq2 === normalizedFqID
    )
    .filter(([fq1, fq2, cross]) => cross.length > 1)
    .map(([fq1, fq2, cross]) => [fq1 === normalizedFqID ? fq2 : fq1, cross]);

  // useEffect(() => {
  //   // if logout, it can be not exist
  //   if (namesFieldRef.current) {
  //     const end = namesFieldRef.current.value.length;
  //     namesFieldRef.current.setSelectionRange(end, end);
  //     namesFieldRef.current.focus();
  //   }
  // }, []);

  const namesJoined = names.join(", ");
  const [namesValue, setNamesValue] = useState(namesJoined);
  useEffect(() => {
    setNamesValue(namesJoined);
  }, [namesJoined]);

  const FEATURE_WITH_REMOVE = __DEV__;

  return (
    <>
      <div style={{ marginTop: "10px", marginBottom: "25px" }}>
        {writeAccess && <AddName names={names} fq={fq} team={team} />}

        <div className={"fq-namestab__container"}>
          <div style={{ marginBottom: "10px" }}>
            <b>Позивні:({names.length}): </b>
            <br />
            {!writeAccess && (
              <div className={"fq-namestab__names"}>
                {namesJoined ? namesJoined : <InfoAbsent />}
              </div>
            )}
            {FEATURE_WITH_REMOVE && writeAccess && (
              <div className="fq-namestab__nameslist">
                {names.map((name, i) => {
                  return (
                    <React.Fragment key={name}>
                      <span
                        className={"fq-namestab__name"}
                        title={`Видалити "${name}"`}
                        onDoubleClick={() => {
                          setDoc(
                            doc(db, "names", team),
                            {
                              [fq]: names.filter((n) => n !== name).join(", "),
                            },
                            { merge: true }
                          );
                        }}
                      >
                        {name}
                      </span>
                      {i === names.length - 1 ? "" : ", "}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {/* // TODO: merge textarea with fqfield*/}
            {!FEATURE_WITH_REMOVE && writeAccess && (
              <TextareaAutosize
                spellCheck="false"
                name=""
                id=""
                cols="30"
                rows="5"
                ref={namesFieldRef}
                className="form-control fq-names-textarea"
                value={namesValue}
                onChange={({ target }) => setNamesValue(target.value)}
                onKeyDown={(ev) => {
                  // Enter should blur
                  if (window.event.keyCode === 13) {
                    ev.preventDefault();
                    namesFieldRef.current.blur();
                  }
                }}
                onBlur={({ target }) => {
                  if (namesJoined === target.value) return;
                  // console.log(parseFloat(fqID).toString())
                  setDoc(
                    doc(db, "names", team),
                    {
                      [fq]: _normalizeNames(target.value).join(", "),
                    },
                    { merge: true }
                  );
                }}
              />
            )}
          </div>
          <h5>Кросс-аналіз:</h5>
          <div style={{ marginBottom: "20px" }} className={"fq-cross-links"}>
            {list.length > 0 &&
              list.map(([fq, cross]) => {
                return <FqCross fq={fq} cross={cross} key={fq} />;
              })}

            {list.length === 0 && (
              <i>
                не знайденно пересічень ( показуються тільки ті, в яких два або
                більше
              </i>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const FqCross = ({ fq, cross }) => {
  const chat_title = useSelector(
    ({ frequencies }) => (frequencies[fq] || {}).chat_title
  );
  // console.log(fq, chat_title);
  const navigate = useNavigate();
  return (
    <span key={fq} className={"fq-cross-link-container"}>
      <b
        className="fq-cross-link"
        onClick={() => navigate("/fq/" + normalizeFq(fq))}
      >
        {normalizeFq(fq)}
      </b>{" "}
      {chat_title ? <i>{`[${chat_title}] `}</i> : ""} {cross.join(", ")}
    </span>
  );
};
export default NamesTab;
