import _ from "lodash";
import { __DEV__ } from "../../constants";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import store from "../../store";
import { useSelector } from "react-redux";

let groupsTimeout;
const SignalGroups = ({ saveSettings, setError }) => {
  const [groups, setGroups] = useState([]);
  const profile = useSelector(({ profile }) => profile);
  const settings = useSelector(({ settings }) => settings);
  const linkedSignalGroups = settings.linkedSignalGroups || {};

  const fetchGroups = () => {
    __firebase
      .signalApi({
        type: "groups",
      })
      .then(({ data }) => {
        if (_.isArrayLike(data)) {
          setGroups(data);
        }
        groupsTimeout = setTimeout(
          fetchGroups,
          __DEV__ ? 1000 * 10 : 1000 * 60
        );
      }, setError);
  };

  useEffect(() => {
    fetchGroups();
    return () => clearTimeout(groupsTimeout);
  }, []);

  const toggleGroup = async (id) => {
    let newLinkedSignalGroups;
    if (linkedSignalGroups[id]) {
      newLinkedSignalGroups = _.omit(linkedSignalGroups, id);
    } else {
      const addedGroup = groups.find((g) => id === g.id);
      newLinkedSignalGroups = {
        ...linkedSignalGroups,
        [id]: {
          name: addedGroup.name,
          id: addedGroup.id,
          addedAt: new Date().valueOf(),
        },
      };
    }
    saveSettings({
      linkedSignalGroups: newLinkedSignalGroups,
    });
  };
  return (
    <div>
      {!groups.length && (
        <p>
          <i>Завантажуємо наявні группи...</i>
        </p>
      )}
      {_.sortBy(
        _.pickBy(
          groups,
          ({ name }) => typeof name === "string" && name.length > 0
        ),
        ({ id }) => linkedSignalGroups[id]
      ).map((group) => {
        // console.log(group);

        const { name, id } = group;
        return (
          <div title={id} key={id} className={"settings__group"}>
            <Checkbox
              checked={linkedSignalGroups[id] != null}
              className={""}
              id={`group[${id}]`}
              onChange={() => toggleGroup(id)}
            />
            <label className={""} htmlFor={`group[${id}]`}>
              <b>{name}</b>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default SignalGroups;
