import RussianNouns from "russian-nouns-js";
const rne = new RussianNouns.Engine();

export const reduceArrayOfStringToForms = (acc, text) => {
  let mountain = RussianNouns.createLemma({
    text,
    gender: RussianNouns.Gender.COMMON,
  });

  const h = RussianNouns.CASES.map((c) => {
    return rne.decline(mountain, c);
  });
  acc.push(..._.flatten(_.uniq(h)));
  return acc;
};

const forms = `
осколки
осколками
убитые
мертвые
тяжелый
тяжёлый
легкий
лёгкий
добить
наводись
движение
птичка
дрон
300
триста
200
двесте
двести
триста
ебните
ёбните
йобните
ебашьте
укроп
укропов
укропа
500ый
500
отказ
отказник
отказники
отказников
блиндаж
лом
танк
миномет
миномёт
мина
минировать
работа
фланг
хохлы
хохол
окоп
дави
додави
птички
работаем
огонь
снаряд
эвакуировать
эвакуация
эвакуации
двухсотый
трехсотый
трёхсотый
дожать
бк
подарки
дудка
дудки
ночник
ромашка
танчик
пкм
стрелкотня
бойцы
бойцов
кошмарит
кошмарить
раненный
раненый
раненные
`
  .trim()
  .split("\n")
  .map((s) => s.trim())
  .reduce(reduceArrayOfStringToForms, []);

// RussianNouns.Gender = {
//   "FEMININE": "женский",
//   "MASCULINE": "мужской",
//   "NEUTER": "средний",
//   "COMMON": "общий"
// }

export const TOKENS = _.uniq(forms);
// console.log(TOKENS);
