import { useSelector } from "react-redux";
import { onCopyClick } from "../../helpers";
import { useTeam, useWriteAccess } from "../../hooks";
import React, { useEffect, useRef, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import CopyFeedback from "./CopiedTooltip";

// todo: merge with FqField
const ChatTitle = ({ fq, frequency }) => {
  const stored = useSelector(
    ({ frequencies }) => (frequencies[fq] || {}).chat_title
  );
  const writeAccess = useWriteAccess();
  // const [showCopied, setShowCopied] = useState(false);

  const [value, setValue] = useState(stored);
  const input = useRef();
  const team = useTeam();

  useEffect(() => {
    setValue(stored);
  }, [stored]);

  const save = () => {
    if (!writeAccess) return;
    if (typeof value !== "string") return;
    setDoc(
      doc(db, "frequencies", team),
      {
        [fq]: {
          ...frequency,
          chat_title: value,
        },
      },
      { merge: true }
    );
  };
  const feedbackRef = useRef();

  return (
    <div className={"fqview-chattitle"}>
      {!writeAccess && <div className={"fqview-chattitle__text"}>{stored}</div>}
      {writeAccess && (
        <>
          <div className="input-group">
            <div className="input-group-prepend">
              <button
                className="btn btn-outline-secondary btn-sm"
                type="button"
                // disabled={!value}
                onClick={() => {
                  onCopyClick({
                    fq,
                    chat_title: value,
                  });
                  feedbackRef.current.show();
                }}
              >
                копіювати
                <CopyFeedback ref={feedbackRef} />
              </button>
            </div>
            <input
              ref={input}
              className={"form-control"}
              spellCheck="false"
              type="text"
              placeholder={"Назва радіомережі"}
              defaultValue={value}
              onChange={({ target }) => setValue(target.value)}
              onKeyDown={(ev) => {
                // Enter should blur
                if (window.event.keyCode === 13) {
                  ev.preventDefault();
                  input.current.blur();
                }
              }}
              onBlur={({ target }) => {
                if (target.value === stored) return;

                save();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default ChatTitle;
