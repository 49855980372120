import React, { useCallback, useState } from "react";
import { useTeam } from "../../hooks";
import { shallowEqual, useSelector } from "react-redux";
import _ from "lodash";
import { __DEV__ } from "../../constants";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import formatDate from "date-fns/format";
import { capFirst, normalizeFq } from "../../helpers";
import Alert from "@mui/material/Alert";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

const Etalonka = ({ possibleToWorkWithReports }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const team = useTeam();
  const { names, frequencies, fqmeta } = useSelector(
    ({ names, frequencies, fqmeta }) => ({
      frequencies,
      names: names.list,
      fqmeta,
    }),
    shallowEqual
  );
  const requestReportXlsx = useCallback(() => requestEtalonka("xlsx"), []);
  const requestReportCsv = useCallback(() => requestEtalonka("csv"), []);
  const requestEtalonka = async (format) => {
    try {
      let fqlist = _.uniq([
        ..._.keys(_.pickBy(names, (x) => x.length > 0)),
        ..._.keys(
          _.pickBy(
            frequencies,
            (x) => typeof x.chat_title === "string" && x.chat_title.length > 0
          )
        ),
        ..._.keys(
          _.pickBy(
            fqmeta,
            (x) => _.isArray(x.locations) && x.locations.length > 0
          )
        ),
      ])
        .map((fq) => parseFloat(fq))
        .sort((a, b) => (a > b ? 1 : -1));
      // console.log({
      //   before: fqlist.length,
      //   after: _.uniq(fqlist).length,
      // });
      if (__DEV__) {
        // fqlist = fqlist.slice(0, 30);
      }

      const getEdgeMessageFromFq = async ({ team, fq, last }) => {
        const doc = await getDocs(
          query(
            collection(db, "messages"),
            where("team", "==", team),
            where("frequency", "==", fq),
            orderBy("datetime", last ? "desc" : "asc"),
            limit(1)
          )
        );
        return doc.docs[0] ? doc.docs[0].data() : null;
      };

      const HEADERS = {
        name: "Назва",
        fq: "Частота",
        locations: "Локації",
        abonents: "Позивні",
        factions: "Підрозділи",
        first_message_date: "Перше перехоплення",
        last_message_date: "Останнє перехоплення",
      };

      setLoading(true);

      const fqWithMessages = [];
      let i = 0;
      for await (let fq of fqlist) {
        try {
          const first = await getEdgeMessageFromFq({ team, fq });
          // console.log(fq, first);
          if (first) {
            const last = await getEdgeMessageFromFq({ team, fq, last: true });
            fqWithMessages.push({
              fq,
              first: first.datetime,
              last: last ? last.datetime : null,
            });
          } else {
            fqWithMessages.push({
              fq,
              last: null,
              first: null,
            });
          }
        } catch (err) {
          console.error("something happened ((", err);
          setError(err.toString());
          // fqWithMessages.push({ fq, first: null, last: null });
        }
        setProgress((i / fqlist.length) * 100);
        i++;
      }
      setLoading(false);
      setProgress(0);

      const formatDatetimeForEtalonka = (d) =>
        d ? formatDate(d, "dd.MM.yyyy HH:mm") : "~";

      const data = fqWithMessages.map(({ fq, first, last }) => {
        return {
          [HEADERS.name]: frequencies[fq]
            ? frequencies[fq].chat_title || ""
            : "",
          [HEADERS.fq]: normalizeFq(fq),
          [HEADERS.locations]:
            fqmeta[fq] && fqmeta[fq].locations
              ? fqmeta[fq].locations
                  .map(({ name_ua }) => capFirst(name_ua))
                  .join(", ")
              : "",
          [HEADERS.abonents]: names[fq] ? names[fq].join(", ") : "",
          [HEADERS.factions]:
            fqmeta[fq] && fqmeta[fq].factions
              ? fqmeta[fq].factions.map(({ name }) => name).join(", ")
              : "",
          [HEADERS.first_message_date]: formatDatetimeForEtalonka(first),
          [HEADERS.last_message_date]: formatDatetimeForEtalonka(last),
        };
      });
      const preparedData = data.reduce((acc, item) => {
        const abonents = item[HEADERS.abonents].split(", ");
        acc.push({
          ...item,
          [HEADERS.abonents]: abonents[0],
        });
        abonents.slice(1).forEach((abonent) => {
          acc.push({
            ..._.mapValues(item, () => ""),
            [HEADERS.abonents]: abonent,
          });
        });
        return acc;
      }, []);

      const worksheet = XLSX.utils.json_to_sheet(preparedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `${team}_overview`);

      XLSX.writeFile(
        workbook,
        `Огляд ${team.toUpperCase()} ${formatDate(
          new Date(),
          "dd.MM.yyyy HH-mm"
        )}.${format}`,
        { compression: true }
      );
    } catch (err) {
      setError(err.toString());
      console.error("requestEtalonka", err);
    }
  };

  return (
    <>
      {error && (
        <div className="row">
          <Alert severity="error">{JSON.stringify(error, null, 2)}</Alert>
        </div>
      )}
      <div className="row">
        <div className="col">2. Верхній обзор по наявним частотам</div>
        <div className="col">
          {loading
            ? `Прогружаємо данні повідомлень ${Math.floor(progress, 1)}%`
            : " "}
        </div>
        <div className="col">
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              variant={"contained"}
              onClick={requestReportXlsx}
              disabled={!possibleToWorkWithReports}
            >
              .xlsx
            </Button>
            {/*<Button
              variant={"contained"}
              onClick={requestReportCsv}
              disabled={!possibleToWorkWithReports}
            >
              .csv
            </Button>*/}
          </ButtonGroup>
        </div>
      </div>
    </>
  );
};
export default Etalonka;
