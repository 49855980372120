import React from "react";
import ReactDOM from "react-dom/client";
import "@forevolve/bootstrap-dark/dist/css/bootstrap-dark.css";
import "./index.css";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import FqCopy from "./ui/parts/archive/FqCopy";
import NamesCleaner from "./ui/parts/archive/NamesCleaner.js";
import { Provider } from "react-redux";
import Table from "./ui/SearchTable/Table";
import Cross from "./ui/Cross";
import FQ from "./ui/FQ";
import FireStoreHelper, { Backup } from "./ui/parts/secret/FireStoreHelper";

import { isInIframe } from "./helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey, deepOrange } from "@mui/material/colors";
import App from "./ui/App";
import ukLocale from "date-fns/locale/uk";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MapTab from "./ui/Map";
import Doc from "./ui/Doc";
import Decoder from "./ui/Decoder";
import Settings from "./ui/Settings/Settings";
import Messages from "./ui/Messages/Messages";
import Updater from "./ui/parts/Updater";
import Location from "./ui/Location";
import Reports from "./ui/Reports/Reports";
import store, { persistor } from "./store.js";
import { PersistGate } from "redux-persist/integration/react";

const createRouter = isInIframe ? createHashRouter : createBrowserRouter;

const router = createRouter([
  {},
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Table />,
      },
      {
        path: "/cross",
        element: <Cross />,
      },

      {
        path: "/fq/:fqID",
        element: <FQ />,
      },
      {
        path: "/doc",
        element: <Doc />,
      },
      {
        path: "decoder",
        element: <Decoder />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "/location/:locationID",
        element: <Location />,
      },
    ],
  },
  {
    path: "/map",
    element: <MapTab />,
  },
  // --------------------------------------------------------------
  {
    path: "/fq",
    element: <FqCopy />,
  },
  {
    path: "/names",
    element: <NamesCleaner />,
  },
  {
    path: "/secret/FireStoreHelper",
    element: <FireStoreHelper />,
  },
  {
    path: "/secret/Backup",
    element: <Backup />,
  },
  // --------------------------------------------------------------
]);

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: deepOrange,
    secondary: blueGrey,
  },
  typography: {
    fontSize: 11,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
// React.StrictMode
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            adapterLocale={ukLocale}
            dateAdapter={AdapterDateFns}
          >
            <Updater />
            <RouterProvider router={router} />
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </>
);
