import FgField from "../parts/FgField";
import _ from "lodash";
import { Link } from "react-router-dom";
import { normalizeFq } from "../../helpers";
import React from "react";
import FqFactions from "./FqFactions";
import { useCanaryTeam, useWriteAccess } from "../../hooks";

const InfoTab = ({ fq, frequency }) => {
  const writeAccess = useWriteAccess();
  return (
    <>
      <div className={"row"}>
        <div className="col-sm">
          <FqFactions fq={fq} />

          <FgField
            fq={fq}
            title={"Хто вони"}
            name={"identification"}
            frequency={frequency}
            note={"Замітки по ідентифікації ворога, що відомо про них"}
          />
        </div>
        <div className="col-sm">
          <FgField
            fq={fq}
            title={"Частоти суміжників"}
            name={"neighbors"}
            frequency={frequency}
            // normalizeValue={(str) => {
            //   return str.replace(/(\d+)/);
            // }}
          >
            {({ value }) => {
              if (typeof value !== "string") return null;

              const transformFQListToListOfLinks = (str) => {
                return str
                  .split(",")
                  .map((s) => s.trim())
                  .map((s) => parseFloat(s))
                  .filter((s) => !_.isNaN(s));
              };

              return (
                <div>
                  {transformFQListToListOfLinks(value).map((s) => {
                    return (
                      <Link
                        to={"/fq/" + normalizeFq(s)}
                        key={s.toString()}
                        className={"fq-cross-link"}
                      >
                        {normalizeFq(s)}
                      </Link>
                    );
                  })}
                </div>
              );
            }}
          </FgField>
          <FgField
            fq={fq}
            title={"Замітки"}
            name={"notes"}
            frequency={frequency}
          />
          <FgField
            fq={fq}
            title={"Історичні записи"}
            name={"history"}
            frequency={frequency}
          />
        </div>
      </div>
      <div className="row">
        <div className="col"></div>
        <div className="col"></div>
      </div>
    </>
  );
};
export default InfoTab;
