import { shallowEqual, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isInIframe, normalizeFq } from "../../helpers";
import _ from "lodash";

const getButtonClass = (distance, isCloser) => {
  if (distance > 1.5) return "secondary";
  if (isCloser) return "warning";
  return "primary";
};

function NoFQData({ fq, callback }) {
  const fqMap = useSelector(({ frequencies }) => {
    const selectedFQ = fq.toString();
    const frequenciesKeys = Object.keys(frequencies);

    if (frequenciesKeys.indexOf(selectedFQ) === -1) {
      frequenciesKeys.push(selectedFQ);
    }

    const sortedFqList = frequenciesKeys.sort(
      (a, b) => parseFloat(a) - parseFloat(b)
    );
    const fqIndex = sortedFqList.indexOf(selectedFQ);
    return {
      left: parseFloat(sortedFqList[fqIndex - 1]),
      right: parseFloat(sortedFqList[fqIndex + 1]),
    };
  }, shallowEqual);

  const leftDistance = fq - fqMap.left;
  const rightDistance = fqMap.right - fq;
  const difference = leftDistance - rightDistance;

  const hasRight = typeof fqMap.right === "number" && !_.isNaN(fqMap.right);
  const hasLeft = typeof fqMap.left === "number" && !_.isNaN(fqMap.left);

  const hasNeib = hasRight || hasLeft;

  return (
    <div className={"pb-5 fqnodata text-center"}>
      <p>
        <i>Не знайдено інформації</i> <br /> Cтворіть{" "}
        <Link
          onClick={callback || null}
          to={!callback ? "/fq/" + normalizeFq(fq) : null}
        >
          <u style={{ fontSize: "150%" }}>{normalizeFq(fq)}</u>
        </Link>
        {hasNeib ? ", або оберіть сусідню частоту:" : "частоту."}
      </p>
      {hasNeib && (
        <>
          <div className="btn-group my-1">
            {hasLeft && (
              <Link
                to={"/fq/" + fqMap.left}
                type="button"
                className={`btn btn-mini btn-${getButtonClass(
                  leftDistance,
                  difference < 0
                )}`}
              >
                {normalizeFq(fqMap.left)} ←
              </Link>
            )}
            {hasRight && (
              <Link
                to={"/fq/" + fqMap.right}
                type="button"
                className={`btn btn-mini btn-${getButtonClass(
                  rightDistance,
                  difference > 0
                )}`}
              >
                → {normalizeFq(fqMap.right)}
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default NoFQData;
